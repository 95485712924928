import React,{ useEffect, useContext, useState } from 'react';
import { useForm } from "react-hook-form";
import ClientContext from '../context/Client/clientContext';
import M from "materialize-css";
import CurrencyInput from './partials/InputForCurrency';
import { timer } from '../helpers/timeLimit';
import errorContext from '../context/Error/errorContext';
import useScreenSize from './customHooks/useScreenSize';
import MethodCard from './partials/MethodCards';
import { InputDisplayForCurrency } from './partials/CurrencyMask';
import ClientCustomField from './partials/ClientCustomFields';

const Method = ({ addAmount }) => {

    const clientContext=useContext(ClientContext);
    const { showError } = useContext(errorContext);
    const { styleBrowserDefault, type_app, usMethods, vesMethods, usCoinActive, vesCoinActive, method,  payment, selectedCoin, info, total, changeCoin, changeMethod, nextStep, setTotal, handleError }=clientContext;
    const [ dataMethods, setDataMethods ] = useState({
        methodUser: method.btn_key,
        //coinUser: (Hizo click en una de las tabs? Valor de la tab "bs" o "ds":(Existe el parámetro 'principal' del cliente y tiene métodos con ese valor? Valor de principal "bs" o "ds":(Existe algún método en bs?"bs":"ds")))
        coinUser: (selectedCoin!=="" ? selectedCoin : (info[0].principal && ((info[0].principal==="bs" && vesCoinActive) || (info[0].principal==="ds" && usCoinActive)) ? info[0].principal : (vesCoinActive ? "bs" : "ds")))
    });
    const {methodUser, coinUser} = dataMethods;
    const [totalUser, setTotalUser] = useState(total);
    const { width } = useScreenSize();
    const availWidth = width<=600;
    
    //Validación del form
    const { register, handleSubmit, trigger, formState: { errors } } = useForm({
        reValidateMode:'onChange'
    });

    //Arreglando falla del carrusel con los tabs
    useEffect(() => {
        let carousels = [...document.querySelectorAll('.carousel')];

        //SI SE QUIERE SWIPEAR AL QUE ESTÉ SELECCIONADO AL REGRESAR - KINDA WONKY
        carousels.forEach(c => {
            M.Carousel.init(c);
            var instance = M.Carousel.getInstance(c);
            var selectedCard = c.querySelector(':scope > .carousel-item.active-border');
            var activeCard = c.querySelector(':scope > .carousel-item.active');
            if(selectedCard && (selectedCard !== activeCard)) {
                instance.set(selectedCard.getAttribute("index"));
            }
        })

        M.Tabs.init(document.querySelector('.tabs'));
        
    },[availWidth]);

    useEffect(() => {
        M.AutoInit();
        window.scrollTo(0,0);
    }, []);

    /**
     * Lleva al siguiente paso y muestra el boton de ir atrás
     * @function onSubmit
     * @param {Array<object>} data Parte de la data del form que contiene el monto no formateado y el método de pago
     * @returns {void}
     */
    const onSubmit= (data) =>{
        try{

            if(coinUser==="bs" || coinUser==="ds"){
                changeCoin(coinUser);
            }else{
                throw new Error('1');
            };

            if(methodUser){
                changeMethod(methodUser);
            }else{
                throw new Error('1');
            };

            if(data.totalAmount && type_app===2){
                setTotal(totalUser) //estado para llevar el monto en forma numérica (mascara)

                const amount = data.totalAmount.replace(/\./g,'').replace(',','.'); //el verdadero monto que se manda a los endpoints
                if(coinUser==="ds"){
                    info[0].amount_ds = amount; 
                }else{
                    info[0].amount_bs = amount;
                };     
            }

            delete data.totalAmount;
            let is_data_empty = true;
            if (data){
                // eslint-disable-next-line no-unused-vars
                for (var keys in data) {
                    is_data_empty = false;
                    break;
                }
            }
            let custom_field;
            if (!is_data_empty) {
                custom_field = {};
                Object.entries(data).forEach((cf, i) => {
                    custom_field[cf[0].split("UserCustomField")[1]] = cf[1]
                })
                info[0].custom_field = custom_field;
            }

            nextStep(payment+1);

        }catch(e){
            console.log(e)
            showError(e.message)
            handleError(e)
            timer(7000,"Recibo");
        }
    }

    /**
     * Método que maneja el cambio del input para colocar el monto a pagar
     * @function handleValueChange
     * @param {Number} val El monto que va tipeando el usuario, sin separción decimal
     */
    const handleValueChange = val => {
        // eslint-disable-next-line
        setTotalUser(val);
    };

    /**
     * Método que maneja el cambio en el método de pago
     * @function setMethod
     * @param {Event} e Evento que cambia la tarjeta seleccionada en el carrusel
     * @returns {void}
     */
    const setMethod = e => {
        setDataMethods({
            ...dataMethods,
            methodUser: e.currentTarget.getAttribute("value")
        });
    }

    /**
     * Método que maneja el cambio en el método de pago
     * @function setCoin
     * @param {Event} e Evento que cambia la moneda seleccionada de los tabs
     * @returns {void}
     */
    const setCoin = e => {
        if(coinUser !== e.target.id){
            setDataMethods({
                ...dataMethods,
                coinUser: e.target.id,
                methodUser: undefined
            });
            if(availWidth){
                let carousels = [...document.querySelectorAll('.carousel')];
                carousels.forEach(c => {
                    M.Carousel.init(c);
                });
            }
        }
    }

    // State for customfield
    const cfInitialState = {}
    const[cfData, setCfData]=useState(cfInitialState);

    /**
     * Método que maneja el en los custom fields
     * @function handleChangeCustomfield
     * @param {Event} e Evento que representa el valor de un campo
     * @param {Object} errors El posible error de un campo
     * @returns {void}
     */
     const handleChangeCustomfield=(e, errors)=>{
        if(errors){
            trigger(e.target.name);
        }
        if (e.target.type === "radio") {
            setCfData({
                ...cfData,
                [e.target.name]:e.target.id
            })
        }else if(e.target.type === "checkbox"){
            setCfData({
                ...cfData,
                [e.target.name]:e.target.checked
            })
        }else{
            setCfData({
                ...cfData,
                [e.target.name]:e.target.value
            })
        }
    }

    return (
        <form
            className="col s12"
            onSubmit={handleSubmit(onSubmit)}
        >
        <div className="row center-align">
            <div className="col s12 m8 offset-m2 padding-bottom-tabs">
                <ul className="tabs">
                    {vesCoinActive &&
                        (<li className="tab col s3">
                            <a
                                className={`left-tab tabOpt ${!usCoinActive ? 'centerTab' : ''} ${coinUser === "bs" ? 'active' : ''}`} 
                                href="#bolivares"
                                id="bs"
                                onClick={setCoin}
                            >
                                Bolívares
                            </a>
                        </li>)
                    }
                    {usCoinActive &&
                        (<li className="tab col s3">
                            <a 
                                className={`right-tab tabOpt ${!vesCoinActive ? 'centerTab' : ''}  ${coinUser === "ds" ? 'active' : ''}`} 
                                href="#dolares"
                                id="ds"
                                onClick={setCoin}
                            >
                                Dólares
                            </a>
                        </li>)
                    }
                </ul>
            </div>

            <div className="input-field col s12 m11">
                {addAmount===true ?
                    (
                        <CurrencyInput
                            name="totalAmount" 
                            id="totalAmount"
                            onValueChange={handleValueChange}
                            value={totalUser}
                            register={register("totalAmount", {
                                validate: value => value!=="0,00" || 'El monto es requerido'
                            })}
                            error={errors.totalAmount}
                        />
                    ) :
                    (
                        <InputDisplayForCurrency 
                            amount={coinUser==="bs" ? info[0].amount_bs : info[0].amount_ds }
                        />
                    )
                }
                <label className='active' htmlFor="totalAmount">Monto a Pagar</label>
                <span className="helper-text invalid" data-error="wrong" >
                    {errors.totalAmount && errors.totalAmount.message}
                </span>
            </div>

            {("fields" in info[0] && info[0]["fields"].length > 0) &&
                info[0]["fields"].map((m, i) => (
                    <ClientCustomField
                        key={i}
                        fieldInfo={m}
                        handleChange={handleChangeCustomfield}
                        register={register}
                        errors={errors}
                        styleBrowserDefault={styleBrowserDefault}
                    />
                ))
            }

            <div className="input-field col m11 no-margin margin-bottom-2">
                <div className="titles responsivePadding">
                    Pagar con
                </div>
            </div>

            {availWidth && <div className="col s12 desliza">Desliza para ver todos los métodos de pago</div>}

                {vesCoinActive &&
                    <div id="bolivares" className="col s12 m11 pb2">
                        <MethodCard
                            methodArray={vesMethods}
                            currentMethod={methodUser}
                            setMethod={setMethod}
                        />
                    </div>
                }
                {usCoinActive &&
                    <div id="dolares" className="col s12 m11 pb2">
                        <MethodCard
                            methodArray={usMethods}
                            currentMethod={methodUser}
                            setMethod={setMethod}
                        />
                    </div>
                }
            <div className="input-field col s12 m11">
                <button
                    className="buttonPay"
                    disabled={
                        methodUser===undefined || 
                        (type_app===2 && totalUser===0)
                    }
                >
                    pagar
                </button>
            </div>
        </div>
        </form>
     );
}
 
export default Method;