import React, { useEffect, useContext } from 'react';
import { reformatDate, reformatTime, timeForP2c, currency, dateBill } from '../helpers/str';
import CurrencyMask from './partials/CurrencyMask';
import ClientContext from '../context/Client/clientContext';
import { timer, stopGlobalTimer, stopTimer2 } from '../helpers/timeLimit';
import NotFound from './NotFound';
import { Fragment } from 'react';
import { EmailShareButton, EmailIcon, WhatsappShareButton, WhatsappIcon, TelegramShareButton, TelegramIcon } from 'react-share';
import useScreenSize from './customHooks/useScreenSize';

const Recibo = ({ viewData, authFailed }) => {

    const {data} = viewData;
    const clientContext=useContext(ClientContext);
    const { info, selectedCoin, method, type_app }=clientContext;
    const [ client, origin ] = info;
    const {date, time} = dateBill(new Date(Date.now()));
    const { width } = useScreenSize();
    const shareText = `Ubiipagos informa: 
        La transacción por ${currency(selectedCoin)} ${data.m && data.m.toString()} se ha realizado satisfactoriamente. 
        ${data.date && `Fecha: ${reformatDate(data.date)}.`}
        ${data.ref && `Referencia: ${data.ref}`}`

    //Envía la respuesta a la página del cliente
    useEffect(() => {
        if(window.opener && (window.location.pathname==="" || window.location.pathname==="/")){
            const custom_field = {
                ...(info[0].custom_field && info[0].custom_field)
            }
            let is_cf_empty = true
            // eslint-disable-next-line no-unused-vars
            for (var x in custom_field) { is_cf_empty = false; break; }
            let finalResponse = {
                ref: data.ref ? data.ref : "",
                order: client.orderId,
                orderID: client.orderId,
                codR: data.codR,
                codS: data.codS,
                ...(data.ME && {ME: data.ME}),
                m: data.m ? data.m.toString() : (method.coin==="bs" ? client.amount_bs : client.amount_ds),
                currency: selectedCoin==="ds" ? 'USD' : 'VES',
                method: method.btn_alias,
                tipo_pago: method.btn_alias,
                M: data.M,
                R: data.R,
                date: data.date,
                trace: data.trace,
                custom_field: !is_cf_empty ? custom_field : {},
                user: info[0].client?.client_email ? info[0].client.client_email : "",
                ...(method.btn_bank && {bank: method.btn_bank}),
            }
            window.opener.postMessage(finalResponse, origin);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //Se coloca un timer para cerrar la página
    useEffect(() => {
        window.scrollTo(0,0);
        stopGlobalTimer();
        stopTimer2();
        let time;
        time = timer(20000,"Recibo");

        return function cleanup () {
            clearTimeout(time)
        }  
      }, []);       

    if(Object.keys(data).length === 0 || data.M === "ERROR" || authFailed===true) return <NotFound message={(authFailed===true && data.R==="1" && data.codS) ? data.codS : "Ocurrió un problema, por favor, vuelva a intentar"} retryButton={authFailed===true} image='/images/error_broken.svg'/>;
    
    /**
     * Permite compartir el resultado de la transacción según los métodos disponibles por el navegador
     * @async
     * @function share
     * @returns {void}
     */
    const share = () => {
        if (navigator.share) {
            navigator.share({
                title: "Resultado de la transacción",
                text: shareText
            }).then(() => {
                console.log('Has compartido tu factura!');
            })
            .catch(console.error);
        } else {
            // Fallback
            console.log("Lo sentimos, tu navegador no permite compartir.")
        }
    }

    return (
        <div className="col s12 m9 shadow frontCard">
            <div className="payment">
            <div style={{visibility: process.env.REACT_APP_UBII_LOGO==='false'?'hidden':'visible', height:"2.6rem"}} className="col s12 center-align" id="logo"><img style={{marginLeft:"0.3rem", height:"inherit"}} src="https://www.ubiipagos.com/img/logo.png" alt="logo"/></div>
                    <div className="row center-align">
                        <div className="col s12 l12 titles-2 center-align title-movement">
                            <h5 >{method.btn_alias === "CASH" ? "¡Pago pendiente!" : data.R === "0" ? "¡Pago procesado exitosamente!":"¡Pago fallido!"}</h5>
                        </div>

                        {method.btn_alias === "CASH" ?
                            // CLOCK
                            (
                                <svg className="resultAnim" viewBox="0 0 130.2 130.2" data-testid="check">
                                    <circle className="circle-three-dashes" fill="none" stroke="#FFBC00" strokeWidth="5" strokeMiterlimit="10" cx="65.1" cy="65.1" r="59.1"/>
                                    <polyline className="clock" fill="none" stroke="#FFBC00" strokeWidth="5" strokeLinecap="round" strokeMiterlimit="10" points="65,35 65,65 41,65"/>
                                    <circle className="clock-middle" cx="65" cy="65" r="7" stroke="none" strokeWidth="0" fill="#FFBC00"/>
                                </svg>
                            ) 
                            :
                            data.R === "0" ?
                            // CHECK
                            (
                                <svg className="resultAnim" viewBox="0 0 130.2 130.2" data-testid="check">
                                    <circle className="circle" fill="none" stroke="#25d447" strokeWidth="5" strokeMiterlimit="10" cx="65.1" cy="65.1" r="59.1"/>
                                    <polyline className="check" fill="none" stroke="#25d447" strokeWidth="5" strokeLinecap="round" strokeMiterlimit="10" points="111,27.4 56.5,85.8 32.8,64.5 "/>
                                </svg>
                            )
                            :
                            // X
                            (
                                <svg className="resultAnim" viewBox="0 0 130.2 130.2"  data-testid="x">
                                    <circle className="circle" fill="none" stroke="#990033" strokeWidth="4" strokeMiterlimit="10" cx="65.1" cy="65.1" r="59.1"/>
                                    <line className="line" fill="none" stroke="#990033" strokeWidth="4" strokeLinecap="round" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3"/>
                                    <line className="line" fill="none" stroke="#990033" strokeWidth="4" strokeLinecap="round" strokeMiterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2"/>
                                </svg>                
                            )
                        }

                        <div id="box-bill" className="input-field col s12 m12 l12 bill-card appear-effect">
                            <div className="row center-align no-margin-bottom">
                                {data.date && <div className="col s4 offset-s2 center-align"><span className="billItem"><p className="noMargin">{reformatDate(data.date)}</p></span></div>}
                                {data.time && <div className="col s4 center-align"><span className="billItem"><p className="noMargin">{timeForP2c(data.time)}</p></span></div>}
                                {!data.time && data.date && <div className="col s4 center-align"><span className="billItem"><p className="noMargin">{reformatTime(data.date)}</p></span></div>}
                                {!data.time && !data.date && 
                                    (
                                        <>
                                            <div className="col s4 offset-s2 center-align"><span className="billItem"><p className="noMargin">{date}</p></span></div>
                                            <div className="col s4 center-align"><span className="billItem"><p className="noMargin">{time}</p></span></div>
                                        </>
                                    )
                                }
                            </div>

                            <Fragment>
                                <div className="col s12 center-align margin-top-08">
                                    <h5><b>{info[2].name_comercio}</b></h5>
                                </div>

                                <div className="col s12 center-align">
                                    <h5><b>{method.btn_alias === "CASH" ? "En espera de pago" : "Comprobante de pago"}</b></h5>
                                </div>

                                <div className="col s12 center-align" style={{marginBottom:"1rem"}}>
                                    <h4 className={`comp ${method.btn_alias === "CASH" ? "ubiiYellow" : data.R === "0" ? "ubiGreen" : "ubiRed"}`}>{(data.R === "0" && method.btn_alias !== "CASH") ? 'Aprobado' : data.codS ? data.codS : 'Fallido'}</h4>
                                </div>
                            </Fragment>

                            {data.R === "0" ? (
                                <div className="row">
                                    <div className="col s12 billItem2"><p className="col s5 noMargin move-left">{method.btn_alias === "CASH" ? "Método" : "Pagado con"}</p><span className="col s7"><p className="noMargin move-right"><b>{method.btn_name}</b></p></span></div>
                                    {data.ref && <div className="col s12 billItem2"><p className="col s5 noMargin move-left">Referencia</p><span className="col s7"><p className="noMargin move-right"><b>{data.ref}</b></p></span></div>}
                                </div>
                            ) : (
                                <Fragment>
                                    <span className="billItem2"><p className="noMargin">Se ha presentado un error al momento de procesar tu pago y no se efectuó</p></span>
                                    <div className="row">
                                        <div className="col s12 billItem2"><p className="col s5 noMargin move-left">Método</p><span className="col s7"><p className="noMargin move-right"><b>{method.btn_name}</b></p></span></div>
                                        <div className="col s12 billItem2"><p className="col s5 noMargin move-left">Monto</p><span className="col s7"><p className="noMargin move-right"><b><CurrencyMask value={method.coin==="bs" ? client.amount_bs : client.amount_ds} currency={currency(selectedCoin)}/></b></p></span></div>
                                    </div>
                                    {(type_app!==1) &&
                                        <Fragment>
                                            <div className="col s12 center-align margin-top-08">
                                                <h5><b>¿Desea volver a intentarlo?</b></h5>
                                            </div>
                                        
                                            <div className="input-field col s12">
                                                <button
                                                    className="buttonPay2"
                                                    onClick={()=> window.location.reload()}
                                                >
                                                    Volver a intentarlo
                                                </button>
                                            </div>
                                            {window.opener && <span className="billItem2" onClick={()=> window.close()}><p className="noMargin ubiiBlue">Salir</p></span>}
                                        </Fragment>
                                    }
                                </Fragment>
                            )}

                            {data.R==="0" && (
                                <div className="row center-align">
                                    <div className="col s4 offset-s2 center-align titles-2"><h5>Total</h5></div>
                                    <div className="col s6 center-align titles-2"><h5><CurrencyMask value={data.m ? data.m.toString() : (method.coin==="bs" ? client.amount_bs : client.amount_ds)} currency={currency(selectedCoin)}/></h5></div>
                                </div>
                            )
                            }

                            {data.R==="0" && type_app!==1 && 
                                <Fragment>
                                    <div className="col s12">
                                        <div className="icon-share" rel="nofollow noopener">
                                            <img className="images-bill" onClick={() => {window.print();return false;}} src="/images/icons/download.svg" alt="Descargar Factura"/>
                                        </div>
                                        { width<=600 ?
                                            (
                                                <div className="icon-share" rel="nofollow noopener">
                                                    <img className="images-bill" onClick={share} src="/images/icons/share.svg" alt="Compartir Factura"/>
                                                </div>
                                            ) : 
                                            (
                                                <Fragment>
                                                    <WhatsappShareButton
                                                        url={shareText}
                                                        className="icon-share"
                                                    >
                                                        <WhatsappIcon size={35} round={true}/>
                                                    </WhatsappShareButton>
                                                    <TelegramShareButton
                                                        url={shareText}
                                                        className="icon-share"
                                                    >
                                                        <TelegramIcon size={35} round={true}/>
                                                    </TelegramShareButton>
                                                    <EmailShareButton
                                                        url={shareText}
                                                        className="icon-share"
                                                    >
                                                        <EmailIcon size={35} round={true}/>
                                                    </EmailShareButton>
                                                </Fragment>
                                            )
                                        }
                                    </div>
                                </Fragment>
                            }

                        </div>

                        <div className="margin-top-08 col s12 l12 titles-2 center-align">
                            <h5 id="ubiipagos">Desarrollado por Ubiipagos</h5>
                        </div>
                    </div>
                
            </div>   
        </div>
     );
}
 
export default Recibo;