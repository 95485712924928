import React , {useReducer, useContext} from 'react';
import C2pContext from '../C2p/c2pContext';
import C2pReducer from '../C2p/c2pReducer';
import { PAGO_C2P } from '../../types';
import ClientContext from '../Client/clientContext';
import errorContext from '../Error/errorContext';

const C2pState = (props) => {

    //State
    const initialState ={
        dataC2p:null,
        processC2p:false
    }

    const clientContext=useContext(ClientContext);
    const {setLoading,method,handleError,pay}=clientContext; 
    const { showError } = useContext(errorContext);

    //dispacth para ejecutar las acciones
    const [state, dispatch] = useReducer(C2pReducer,initialState);

    /**
     * Envía datos al endpoint final de c2p. Coloca en el contexto el resultado de la transacción, o el error si hubo alguno 
     * @async
     * @function sendC2p
     * @param {object} datos información enviada desde el formulario de c2p
     * @returns {void}
     */
    const sendC2p = async datos =>{
        try {
            const response = await pay('payment_c2p', datos, method.btn_key);
            dispatch({
                type:PAGO_C2P,
                payload:response
            })
            setLoading(false)

        } catch (error) {
            console.log(error);
            showError('1');
            handleError(error);
            setLoading(false)
        }
    }

    return ( 
        <C2pContext.Provider
            value={{
                dataC2p: state.dataC2p,
                processC2p:state.processC2p,
                sendC2p
            }}
        >
            {props.children}
        </C2pContext.Provider>
     );
}
 
export default C2pState;