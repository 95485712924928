import React from 'react';
import InputMask from "react-input-mask";
import CopyClipboard from "./CopyClipboard";

const Input = ({register, type, iconRight, ...object}) => {
    return (
        <div className={iconRight?"div-wrapper-input":""}>
            <InputMask
                {...object}
                maskPlaceholder=""
                type={type ? type: "text"}
                {...register}
            />
            {iconRight && <CopyClipboard value={object.value} id={object.id} icon={iconRight}/>}
        </div>
    );
}
export default Input;