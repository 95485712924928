import React from 'react';
import { Fragment } from 'react';
import Input from './Input';

const ClientCustomField = ({fieldInfo, handleChange, register, errors, styleBrowserDefault}) => {
    let inputType = fieldInfo["type"];
    switch(inputType){
        case "radio":
            return <ClientCustomFieldRadio
                        fieldInfo={fieldInfo}
                        handleChange={handleChange}
                        register={register}
                        errors={errors}
                    />;
        case "checkbox":
            return <ClientCustomFieldCheckbox
                        fieldInfo={fieldInfo}
                        handleChange={handleChange}
                        register={register}
                        errors={errors}
                    />;
        case "select":
            return <ClientCustomFieldSelect
                        fieldInfo={fieldInfo}
                        handleChange={handleChange}
                        register={register}
                        errors={errors}
                        styleBrowserDefault={styleBrowserDefault}
                    />;
        case "input":
            return <ClientCustomFieldInput
                        fieldInfo={fieldInfo}
                        handleChange={handleChange}
                        register={register}
                        errors={errors}
                    />;
        default:
            return null;
    }
}

const ClientCustomFieldInput = ({fieldInfo, handleChange, register, errors}) => {
    let fieldName = fieldInfo["name"];
    let fieldId = `inputUserCustomField${fieldName}`;
    let required = fieldInfo["mandatory"];
    let inputLabel = fieldInfo["description"];
    return (
        <div className="input-field col s12 m11">
            <label className='active label-payments' htmlFor={fieldId}>{inputLabel}</label>
            <Input
                name={fieldId}
                id={fieldId}
                maxLength="140"
                register={register(fieldId, {
                    required:{value: required, message: `${fieldName} es requerido/a`},
                    maxLength:{value: 140, message: "este campo debe tener máximo 140 caracteres"},
                    onChange: (e) => handleChange(e, errors)
                })}
                placeholder={`${fieldName}...`}
                className={errors[fieldId] ? "red-border": "input-form"}
            />
            <span className="helper-text invalid" data-error="wrong" >
                {errors[fieldId] && errors[fieldId].message}
            </span>
        </div>
     );
}

const ClientCustomFieldSelect = ({fieldInfo, handleChange, register, errors, styleBrowserDefault}) => {
    let fieldName = fieldInfo["name"];
    let fieldId = `selectUserCustomField${fieldName}`;
    let required = fieldInfo["mandatory"];
    let inputLabel = fieldInfo["description"];
    let select_options = Object.entries(JSON.parse(fieldInfo["options"]));
    return (
        <div className={`input-field col s12 m11 ${errors[fieldId] ? "border-input-dropdown-error" : ""}`}>
            <label className='active label-payments' htmlFor={fieldId}>{inputLabel}</label>
            <select
                name={fieldId}
                id={fieldId}
                {...register(fieldId, {
                    required:{value: required, message: `${fieldName} es requerido/a`},
                    onChange: (e) => handleChange(e, errors)
                })}
                className={`input-form ${styleBrowserDefault && 'browser-default'}`}
                autoComplete="off"
            >
            <option value="">- {fieldName} -</option>
            {select_options.map((b, i) => (
                <option key={b[0]} value={b[0]}>{b[1]}</option>
            ))}
            </select>
            <span className="helper-text invalid" data-error="wrong" >
            {errors[fieldId] && errors[fieldId].message}
            </span>
        </div>
     );
}

const ClientCustomFieldCheckbox = ({fieldInfo, handleChange, register, errors}) => {
    let fieldName = fieldInfo["name"];
    let fieldId = `cboxUserCustomField${fieldName}`;
    let inputLabel = fieldInfo["description"];
    let cbox_options = Object.entries(JSON.parse(fieldInfo["options"]));
    return (
        <>
            <div className="input-field col s12 m11 inputs-align-left">
                <label className='active label-payments' htmlFor={fieldId}>{inputLabel}</label>
                {cbox_options.map((b, i) => (
                    <Fragment key={i}>
                        <p>
                            <label>
                                <input
                                    type="checkbox"
                                    id={b[0]}
                                    name={fieldId}
                                    value={b[0]}
                                    {...register(fieldId, {
                                        onChange: (e) => handleChange(e, errors)
                                    })}
                                />
                                <span className="active label-payments custom-checkbox">{b[1]}</span>
                            </label>
                        </p>
                    </ Fragment>
                ))}
                <span className="helper-text invalid" data-error="wrong">
                    {errors[fieldId] && errors[fieldId].message}
                </span>
            </div>
        </>
     );
}

const ClientCustomFieldRadio = ({fieldInfo, handleChange, register, errors}) => {
    let fieldName = fieldInfo["name"];
    let fieldId = `radioUserCustomField${fieldName}`;
    let inputLabel = fieldInfo["description"];
    let cbox_options = Object.entries(JSON.parse(fieldInfo["options"]));
    let required = fieldInfo["mandatory"];
    return (
        <>
            <div className="input-field col s12 m11 inputs-align-left">
                <label className='active label-payments' htmlFor={fieldId}>{inputLabel}</label>
                {cbox_options.map((b, i) => (
                    <Fragment key={i}>
                        <p>
                            <label>
                                <input
                                    type="radio"
                                    className="with-gap"
                                    id={b[0]}
                                    value={b[0]}
                                    name={fieldId}
                                    {...register(fieldId, {
                                        required:{value: required, message: `${fieldName} es requerido/a`},
                                        onChange: (e) => handleChange(e, errors)
                                    })}
                                />
                                <span className="active label-payments custom-checkbox">{b[1]}</span>
                            </label>
                        </p>
                    </Fragment>
                ))}
                <span className="helper-text invalid" data-error="wrong">
                    {errors[fieldId] && errors[fieldId].message}
                </span>
            </div>
        </>
     );
}

export default ClientCustomField;