/**
 * Pasa un string a mayúscula
 * @function uppercase
 * @param {string} string string a convertir
 * @returns {string} el string convertido
 */
export function uppercase(string) {
    return string.toUpperCase();
}

/**
 * Cambia todos los caracteres de un string que no sean dígitos por ""
 * @function removeNonDigits
 * @param {string} value cadena a modificar
 * @returns {string} el string convertido
 */
export function removeNonDigits(value){
    return value.replace(/\D/g, "");
}

/**
 * Formatea un tipo específico de fecha retornado por el backend
 * @function reformatDate
 * @param {string} date fecha a convertir en formato dd/mm/yyyy hh:mm:ss
 * @returns {string} fecha convertida
 */
export function reformatDate(date){
    return date.slice(0,10);
}

/**
 * Extrae la hora de la fecha pasada por parámetros 
 * @function reformatTime
 * @param {string} date fecha en formato dd/mm/yyyy hh:mm:ss
 * @returns {string} hora correspondiente a la fecha pasada
 */
export function reformatTime(date){
    return date.slice(11);
}

/**
 * Convierte un tiempo en milisegundos a un tiempo en horas
 * @function timeForP2c
 * @param {string} time tiempo en milisegundos
 * @returns {string} tiempo en horas
 */
export function timeForP2c(time){
    return (new Date(parseInt(time))).toString().slice(16,24);
}

/**
 * Modifica la fecha actual a un formato aceptado por el backend de P2C
 * @function dateP2c
 * @param {Date} date fecha actual
 * @returns {string} fecha formateada
 */
export function dateP2c(date){

    const month = date.getMonth() +1;
    const day = date.getDate();
    const year = date.getFullYear();

    const fMonth = month > 9 ? month : "0"+month;
    const fDay = day > 9 ? day : "0"+day;

    return year+""+fMonth+""+fDay;
}

/**
 * Modifica una fecha de Javascript para poder mostrarla en la factura
 * @function dateBill
 * @param {Date} date fecha actual
 * @returns {Object} diccionario con la fecha y la hora
 */
 export function dateBill(date){

    const month = date.getMonth() +1;
    const day = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const sec = date.getSeconds();

    const fMonth = month > 9 ? month : "0"+month;
    const fDay = day > 9 ? day : "0"+day;

    return {
        date: `${fDay}/${fMonth}/${year}`,
        time: `${hours}:${minutes}:${sec}`
    };
}

/**
 * Revisa si un campo que debe ser únicamente de caracteres contiene dígitos, comas o puntos
 * @function noNumbers
 * @param {string} str cadena a evaluar
 * @returns {boolean} falso si encuentra números y verdadero si no
 */
export function noNumbers(str){
    return !str.match(/[\d.,]/g);
}

export const emailValidator = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

/**
 * Algoritmo de Luhn para validar veracidad de tarjetas de crédito
 * @function validateCard
 * @param {string} value la tarjeta a evaluar
 * @returns {boolean} si es válida o no
 */
export function validateCard(value){

    value = value.replace(/\D/g, "");
    if (/[^0-9-\s]+/.test(value)) return false;

    let nums = value.split("").map(Number);

    if(value.length%2===0){
        nums = nums.map((num, i) => i%2===0 ? num*2 : num);
    }else{
        nums = nums.map((num,i) => i%2===0 ? num : num*2);
    }

    nums = nums.map(num => num>9 ? num-9 : num);

    const res = nums.reduce((acc, num) => acc+=num, 0);
    return (res%10===0);
}

/**
 * @function currency
 * @param selectedCoin si se está trabajando en dolares o bolívares
 * @returns la moneda con la que se realizó el pago
 */
export const currency = (selectedCoin) =>{
    return selectedCoin==="ds"? '$':'Bs. ';
}

/**
 * Algoritmo para validar validez del RIF que el usuario escribe
 * @function validateRif
 * @param {string} value el rif a evaluar
 * @param {string} letter el tipo de persona
 * @returns {boolean} si es válido o no
 */
 export function validateRif(value, letter){

    // value = value.replace(/\D/g, "");
    if (/[^0-9-\s]+/.test(value) || value.length===0) return false;
    if (/[^CVEJPG]+/.test(letter) || letter.length!==1) return false;

    if(value.length===9){
        const mult = [3, 2, 7, 6, 5, 4, 3, 2];
        const special_word_dict = {'V':1, 'E':2, 'C':3, 'J':3, 'P':4, 'G':5};
        let arr = value.split('');
        let specialNum = arr.pop();
        let newVal = 0;
        arr.forEach((m,i) => {
            newVal += parseInt(m)*mult[i];
        });
    
        newVal += special_word_dict[letter] * 4;
    
        const res = 11-(newVal%11);
        let verifyNum = res>=10 ? 0 : res;
        
        return verifyNum === parseInt(specialNum);
    }

    return true;
}

/**
 * Algoritmo para validar un número de cuenta bancario de Venezuela
 * @function isBolivarAccount
 * @param {string} account el número de cuenta
 * @returns {boolean} si es válido o no
 */
export const isBolivarAccount = account => {
    if (account.length === 0) return false

    account.replace(/-/g, '')
    account.replace(/\s/g, '')

    if (account.length !== 20) return false

    const account_in_array = [...account]
    const account_in_array_numeric = account_in_array.map(number => Number(number))

    //VALIDAR EL PRIMER DIGITO VALIDADOR
    let firstDigit

    const first_sum = account_in_array_numeric[0]*3 + 
                      account_in_array_numeric[1]*2 + 
                      account_in_array_numeric[2]*7 +
                      account_in_array_numeric[3]*6 +
                      account_in_array_numeric[4]*5 +
                      account_in_array_numeric[5]*4 +
                      account_in_array_numeric[6]*3 +
                      account_in_array_numeric[7]*2
        
    const first_modulus = first_sum % 11

    switch (first_modulus) {
        case 0: firstDigit = 1
                break
        case 1: firstDigit = 0
                break
        default: firstDigit = 11 - first_modulus
    }

    if (firstDigit !== account_in_array_numeric[8]) return false

    //VALIDAR EL SEGUNDO DIGITO VALIDADOR
    let secondDigit

    const secondSum = account_in_array_numeric[10]*5 +
                      account_in_array_numeric[11]*4 +
                      account_in_array_numeric[12]*3 +
                      account_in_array_numeric[13]*2 +
                      account_in_array_numeric[14]*7 +
                      account_in_array_numeric[15]*6 +
                      account_in_array_numeric[16]*5 +
                      account_in_array_numeric[17]*4 +
                      account_in_array_numeric[18]*3 +
                      account_in_array_numeric[19]*2 +
                      account_in_array_numeric[4]*3 +
                      account_in_array_numeric[5]*2 +
                      account_in_array_numeric[6]*7 +
                      account_in_array_numeric[7]*6
    
    const secondModulus = secondSum % 11

    switch(secondModulus) {
        case 0: secondDigit = 1
            break
        case 1: secondDigit = 0
            break
        default: secondDigit = 11 - secondModulus
    }

    if (secondDigit !== account_in_array_numeric[9]) return false

    return true
}

/**
 * Función que rellena un String con ceros a la izquierda hasta una determinada longitud
 * @function pad
 * @param {String} n el String a rellenar
 * @param {Number} width el tamaño final del String
 * @returns {String} el String final
 */
export const pad = (n, width) => {
    n = n + '';
    return n.length >= width ? n : 
        new Array(width - n.length + 1).join('0') + n;
}