import React, { useContext, useReducer } from 'react';
import ClientContext from '../Client/clientContext';
import zelleReducer from './zelleReducer';
import { PAGO_ZELLE, TOKEN_ZELLE } from '../../types';
import zelleContext from './zelleContext';
import errorContext from '../Error/errorContext';
import clientAxios from '../../config/axios';

const ZelleState = props => {

  //States
    const initialState = {
      dataZelle: "",
      processZelle: false,
      tokenMW: undefined
    }

    const clientContext = useContext(ClientContext);
    const {method, info, handleError, pay, setLoading} = clientContext;
    const { showError } = useContext(errorContext);

    //Dispatch para ejecutar las acciones
    const [state, dispatch] = useReducer(zelleReducer, initialState);

    /**
     * Envía datos al endpoint final de Zelle. Coloca en el contexto el resultado de la transacción, o el error si hubo alguno 
     * @async
     * @function sendZelle
     * @param {object} datos información enviada desde el formulario de zelle
     * @param {string} provider Proveedor del Zelle
     * @returns {void}
     */
    const sendZelle = async(datos, provider) =>{
      try {
        const response = await pay(`payment${provider}`, datos, method.btn_key);
        dispatch({
          type: PAGO_ZELLE,
          payload: response
        });
        
        setLoading(false)
          
      } catch (error) {
        console.log(error);
        showError('1');
        handleError(error);
        setLoading(false)
      }
    }

    /**
     * Regresa el token necesario para uno de los procesos Zelle
     * @async
     * @function getTokenMW
     * @returns {Object} la respuesta, si es correcta
     */
    const getTokenMW = async() => {
      try{
        const response = await clientAxios.get('/get_token_zelle', {
          headers:{
            Authorization: info[2].token,
            "X-API-KEY": method.btn_key
          }
        });

        if(response.data.R!=="0"){
          throw new Error('1');
        }

        setTokenMW(response.data.token);

      }catch(e) {
        showError('1');
        handleError(e);
        setLoading(false);
      }
    }

    /**
     * Setea el token necesario para uno de los procesos Zelle en el contexto
     * @async
     * @function setTokenMW
     * @returns {void}
     */
    const setTokenMW = (token) => {
      dispatch({
        type: TOKEN_ZELLE,
        payload:token
      })
    }

    return ( 
        <zelleContext.Provider
            value={{
                dataZelle:state.dataZelle,
                processZelle:state.processZelle,
                tokenMW: state.tokenMW,
                sendZelle,
                getTokenMW,
                setTokenMW
            }}
        >
            {props.children}
        </zelleContext.Provider>
    );
}
 
export default ZelleState;