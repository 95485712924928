import React from 'react';
import CurrencyFormat from 'react-currency-format';

const CurrencyMask = ({value, currency}) => {

    //value llega en un formato 1000.00 que aquí se cambia a 1000,00 para poder usarlo en currency format
    //sin que haya conflicto con el separador decimal y el separador de miles
    const aux = value ? value.replace((/\./gi), ',') : undefined;

    return (
        <CurrencyFormat 
            value={aux} 
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={currency ? currency : ""}
            allowNegative={false}
            displayType={'text'} 
        />
 
    );
}
 
export default CurrencyMask;

export const InputDisplayForCurrency = ({amount,insideForm}) => (
    <div data-testid="dont-add-amount" className={`mock-input ${insideForm ? 'in-form': ''}`}>
        <CurrencyMask 
            value={amount}
        />
    </div>
)