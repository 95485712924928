import React, { useState, useContext, useEffect } from 'react';
import { useForm } from "react-hook-form";
import Spinner from '../spinner/Spinner';
import ClientContext from '../../context/Client/clientContext';
import M from "materialize-css";
import debitContext from '../../context/payDebit/debitContext';
import { InputDisplayForCurrency } from '../partials/CurrencyMask';

const TddSelection = () => {

    const clientContext = useContext(ClientContext);
    const { styleBrowserDefault, selectedMethods, info, loading, changeMethodDebit, nextStep } = clientContext;

    const { setOptions } = useContext(debitContext);

    const [interdata, setInter] = useState({
        bank_form: ""
    })

    const {bank_form} = interdata;

    //Validación del form
    const { register, handleSubmit, trigger, formState: { errors } } = useForm({
        mode:"onBlur",
        reValidateMode:"onBlur"
    });

    //Modifica estilos
    useEffect(() => {
        M.AutoInit();
        window.scrollTo(0,0);
    }, []);

    /**
    * Método para actualizar el state del formulario dinámicamente
    * @function handleChange
    * @param {Event} e Evento que cambia un input en el form
    * @returns {void}
    */
     const handleChange=(e, errors)=>{
        if(errors){
          trigger(e.target.name);
        }
        setInter({
            ...interdata,
            [e.target.name] : e.target.value
        })
    }

    /**
     * Recopila los datos necesarios, los transforma y va a realizar el pago
     * @function onSubmit
     * @param {object} interdata la data del form 
     * @returns {void}
     */
    const onSubmit = interdata =>{
        changeMethodDebit(interdata.bank_form);
        setOptions(interdata.bank_form);
        nextStep(3);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
        <div className="margin-top-08 col s12 l12 titles-3 center-align">
            <h5 >Vas a pagar con <span>tarjeta de débito</span> un monto en Bs. de:</h5>
        </div>
            {loading && <Spinner/>}
            <div className="row">
                <div className="input-field col s12">
                    <InputDisplayForCurrency
                        amount={info[0].amount_bs}
                        insideForm
                    />
                </div>

                <div className={`input-field col s12 ${errors.bank_form ? "border-input-dropdown-error" : ""}`}>
                    <label className='active label-payments' htmlFor="bank_form">Selecciona el banco de tu tarjeta</label>
                    <select
                        name="bank_form"
                        id="bank_form"
                        {...register("bank_form", {
                            required:{value:true,message:'El banco es requerido'},
                            onChange: (e) => handleChange(e, errors.bank_form)
                        })}
                        className={`input-form ${styleBrowserDefault && 'browser-default'}`}
                        autoComplete="off"
                    >
                    <option value="">Banco -</option>
                    {selectedMethods.filter(m => m.btn_bank).map((b, i) => (
                        <option key={b.btn_bank} value={b.btn_bank}>{b.btn_bank_name}</option>
                    ))}
                    </select>
                    <span className="helper-text invalid" data-error="wrong" >
                    {errors.bank_form && errors.bank_form.message}
                    </span>
                </div>
                <div className="input-field col s12">
                    <button
                    className="buttonPay"
                    disabled={bank_form===""}
                    >
                        Continuar
                    </button>
              </div>
            </div>
        </form>
     );
}
 
export default TddSelection;