import React, { useContext, useReducer } from 'react';
import ClientContext from '../Client/clientContext';
import cashReducer from './cashReducer';
import { PAGO_CASH } from '../../types';
import cashContext from './cashContext';
import errorContext from '../Error/errorContext';

const CashState = props => {

  //States
    const initialState = {
      dataCash: "",
      processCash: false
    }

    const clientContext = useContext(ClientContext);
    const { setLoading, method, handleError, pay } = clientContext;
    const { showError } = useContext(errorContext);

    //Dispatch para ejecutar las acciones
    const [state, dispatch] = useReducer(cashReducer, initialState);

    /**
     * Envía datos al endpoint final de Ubii app. Coloca en el contexto el resultado de la transacción, o el error si hubo alguno 
     * @async
     * @function sendCash
     * @param {object} datos información enviada desde el formulario de ubii app
     * @returns {void}
     */
    const sendCash = async(datos) =>{
      try {
        const response = await pay('payment_cash', datos, method.btn_key);
    
        dispatch({
          type: PAGO_CASH,
          payload: response,
        });
        
        setLoading(false)
          
      } catch (error) {
        console.log(error);
        showError('1');
        handleError(error);
        setLoading(false)
      }
    }
    


    return ( 
        <cashContext.Provider
            value={{
                dataCash:state.dataCash,
                processCash:state.processCash,
                sendCash
            }}
        >
            {props.children}
        </cashContext.Provider>
    );
}
 
export default CashState;