import React, { useContext, useReducer } from 'react';
import ClientContext from '../Client/clientContext';
import ubiiAppReducer from './ubiiAppReducer';
import { PAGO_UBII } from '../../types';
import ubiiAppContext from './ubiiAppContext';
import errorContext from '../Error/errorContext';

const UbiiAppState = props => {

  //States
    const initialState = {
      dataUbiiApp: "",
      processUbiiApp: false
    }

    const clientContext = useContext(ClientContext);
    const {setLoading,method,handleError, pay} = clientContext;
    const { showError } = useContext(errorContext);

    //Dispatch para ejecutar las acciones
    const [state, dispatch] = useReducer(ubiiAppReducer, initialState);

    /**
     * Envía datos al endpoint final de Ubii app. Coloca en el contexto el resultado de la transacción, o el error si hubo alguno 
     * @async
     * @function sendUbii
     * @param {object} datos información enviada desde el formulario de ubii app
     * @returns {void}
     */
    const sendUbii = async(datos) =>{
      try {
        const response = await pay('payment_ubii_app', datos, method.btn_key);
    
        dispatch({
          type: PAGO_UBII,
          payload: response,
        });
        
        setLoading(false)
          
      } catch (error) {
        console.log(error);
        showError('1');
        handleError(error);
        setLoading(false)
      }
    }
    


    return ( 
        <ubiiAppContext.Provider
            value={{
                dataUbiiApp:state.dataUbiiApp,
                processUbiiApp:state.processUbiiApp,
                sendUbii
            }}
        >
            {props.children}
        </ubiiAppContext.Provider>
    );
}
 
export default UbiiAppState;