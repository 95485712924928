import React,{ useEffect, useContext, useState } from 'react';
import { useForm } from "react-hook-form";
import M from "materialize-css";
import ClientContext from '../../context/Client/clientContext';
import errorContext from '../../context/Error/errorContext';
import CurrencyInput from '../partials/InputForCurrency';
import Spinner from '../spinner/Spinner';
import { timer } from '../../helpers/timeLimit';
import payContext from '../../context/Pay/payContext';
import internacionalContext from '../../context/Internacional/internacionalContext';

const MicroCobro = ({pay_method}) => {

    const clientContext=useContext(ClientContext);
    const { showError } = useContext(errorContext);
    const { loading, method, pay, handleError }=clientContext;
    const { data_client_tdc, setProcessPaymentTdc, pagoData } = useContext(payContext);
    const { data_client_tdcInter, setProcessPaymentInter, pagoInternacional } = useContext(internacionalContext);
    const [chargedAmount, setChargedAmount] = useState(0);
    const [checkingAmount, setCheckingAmount] = useState(false);
    const [falseAmount, setFalseAmount] = useState(false);

    const { register, handleSubmit, formState: { errors } } = useForm({
        reValidateMode:'onChange'
    });

    useEffect(() => {
        M.AutoInit();
        window.scrollTo(0,0);
    }, []);

    /**
     * Método que maneja el cambio del input para colocar el monto a pagar
     * @function handleValueChange
     * @param {Number} val El monto que va tipeando el usuario, sin separción decimal
     */
    const handleValueChange = val => {
        // eslint-disable-next-line
        setChargedAmount(val);
    };

    /**
     * 
     * @function onSubmit
     * @param {Number} data data del formulario
     */
    const onSubmit= async(data) =>{
        //Hacer request aquí mismo
        try{
            setFalseAmount(false);
            setCheckingAmount(true);
            var data_client;
            var api_response;
            let setProcessFunc;
            if(pay_method==="tdc_bvc"){
                data_client = data_client_tdc;
                api_response = pagoData;
                setProcessFunc = setProcessPaymentTdc;
            }else{
                data_client = data_client_tdcInter;
                api_response = pagoInternacional;
                setProcessFunc = setProcessPaymentInter;
            }
            
            const message = {
                ...data_client,
                mVoid: data.totalAmount.replace(/\./g,'').replace(',','.'),
                traceTCP: api_response.data.trace ? api_response.data.trace : null
            }
            const resp = await pay('payment_authorization_check', message, method.btn_key);

            //Si es el monto correcto y transacción aprobada, llamar a setProcessPaymentTdc, que manda a la factura con los datos de la trans
            if(resp.data.R==="0"){
                setProcessFunc(resp);
            //Si es el monto incorrecto, mostrar el error debajo del input
            }else if(resp.data.R==="1" && resp.data.codR==="98"){
                setCheckingAmount(false);
                setFalseAmount(true);
            // Si la tarjeta se bloqueó porque agotó los intentos, la transacción no fue aprobada, o se acabó el tiempo(CodR=97), llamar a setProcessPaymentTdc, que manda a la factura con la info de fallido
            }else{
                setProcessFunc(resp);
            };
        }catch(e){
            showError(e.message);
            handleError(e);
            timer(6000,"Recibo");
        }
    }

    return (
        <form
            className="col s12"
            onSubmit={handleSubmit(onSubmit)}
        >
            {loading && <Spinner/>}
            <div className="margin-top-08 col s12 l12 titles-3 center-align">
                <h5> 
                    <b>
                        <span>Verificación: </span>
                        Por favor ingresa a la cuenta de tu tarjeta y escribe a continuación el monto en dólares que le fue descontado:
                    </b>
                </h5>
            </div>

            <div className="row center-align">
                <div className="input-field col s12">
                    <CurrencyInput
                        name="totalAmount" 
                        id="totalAmount"
                        onValueChange={handleValueChange}
                        value={chargedAmount}
                        register={register("totalAmount", {
                            validate: value => value!=="0,00" || 'El monto es requerido'
                        })}
                        error={errors.totalAmount}
                    />
                </div>

                {checkingAmount && 
                    <div className="col s12" data-testid="checking">
                        <Spinner justLoader/>
                    </div>
                }
                {falseAmount && 
                    <div className="col offset-s1 s10 bad-amount">El monto que ingresó no es el correcto, intente de nuevo.</div>
                }

                <div className="col s12 title-helper">
                    La cantidad se le abonará nuevamente en breve. <br/>
                    <span style={{fontSize:"smaller"}}>Tomamos las medidas necesarias para tu seguridad.</span>
                </div>

                <div className="input-field col s12 margin-top-08">
                    <button
                        className="buttonPay"
                        disabled={
                            chargedAmount===0
                        }
                    >
                        ¡Listo!
                    </button>
                </div>
            </div>
        </form>
    )
}
export default MicroCobro;