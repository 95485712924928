import React, { useContext } from 'react';
import clientContext from '../../context/Client/clientContext';
import NotFound from '../NotFound';
import { InputDisplayForCurrency } from '../partials/CurrencyMask';
import TddBVC from './TddBVC';
import TddCredicard from './TddCreditCard';
import TddMercantil from './TddMercantil';
import TddVenezuela from './TddVenezuela';

const Tdd = ({bank}) => {

    const {info} = useContext(clientContext);

    const RedirectToSelectedBank = () => {
        switch(bank){
            case "venezuela":
                return <TddVenezuela/>;
            case "mercantil":
                return <TddMercantil/>;
            case "credicard":
                return <TddCredicard/>;
            case "bvc":
                return <TddBVC/>;
            default:
                return <NotFound message="Ocurrió un problema, por favor, vuelva a intentar" image='/images/error_broken.svg'/>;
        }
    }
    return (
        <div className="row">
            <div className="margin-top-08 col s12 l12 titles-3 center-align">
                <h5 data-testid="title-tdd">Vas a pagar con <span>tarjeta de débito</span> un monto en Bs. de:</h5>
            </div>
            <div className="input-field col s12">
                <InputDisplayForCurrency
                    amount={info[0].amount_bs}
                    insideForm
                />
            </div>
            <RedirectToSelectedBank/>
        </div>
     );
}
 
export default Tdd;