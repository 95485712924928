import React from 'react';
import { copyToClipboardPrincipal } from '../../helpers/clipboard';
import Tooltip from './Tooltip';

const CopyClipboard = ({value, id, icon}) => {

    const onClick = (e) => {

        const input = document.getElementById(id);
        copyToClipboardPrincipal(value);

        input.classList.remove('copied');
        void input.offsetWidth;
        input.classList.add('copied');
    }

    return (
        <Tooltip
            message="Copiar al portapapeles"
            icon={icon}
            iconClass="clipboard"
            onCLickFn={onClick}
        />
        // <div className="tooltip">
        //     <i 
        //         className="material-icons clipboard"
        //         onClick={onClick}
        //     >
        //         {icon}
        //     </i>
        //     <ReactTooltip borderColor="#00a1fd" className="italic" border={true} textColor="#4d4d4d"  place="top" type="light" effect="solid" multiline={true}/>
        // </div>
    );
}
 
export default CopyClipboard;