import axios from 'axios';

const clientAxios=axios.create({
    baseURL:process.env.REACT_APP_BACKEND_URL
});

clientAxios.defaults.headers['Content-Type'] = 'application/json';
clientAxios.defaults.headers.common['Accept']='application/json';
// clientAxios.interceptors.request.use(async (config) => {
//     try{
        
//     }
//     catch(e)
//     {
        
//     }
//     return config;
// });

export default clientAxios;