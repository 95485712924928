import React from 'react';
import ReactTooltip from 'react-tooltip';

const Tooltip = ({message, icon, iconClass, onCLickFn}) => {
    return ( 
        <div className="tooltip" data-testid="tooltip">
            <i 
                data-tip={message} 
                className={`material-icons ${iconClass}`}
                onClick={onCLickFn ? onCLickFn : undefined}
            >
                {icon}
            </i>
            <ReactTooltip borderColor="#00a1fd" className={`italic ${ onCLickFn ? "hide-tooltip-mobile" : "" }`} border={true} textColor="#4d4d4d"  place="top" type="light" effect="solid" multiline={true}/>
        </div>
     );
}
 
export default Tooltip;
