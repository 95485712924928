import React, { useState, useContext, useEffect } from 'react';
import debitContext from '../../context/payDebit/debitContext';
import { useForm } from 'react-hook-form';
import Spinner from '../spinner/Spinner';
import ClientContext from '../../context/Client/clientContext';
import Input from '../partials/Input';
import M from "materialize-css";
import Tooltip from '../partials/Tooltip';

const TddMercantil = () => {

    //States
    const [dataForm, setData] = useState({
        atype:'',
        tf:'',
        cvv:'',
        month:'',
        year:''
    })

    const [clave, setClave] = useState("");

    const {atype, tf, cvv, month, year} = dataForm;

    const clientContext=useContext(ClientContext);
    const {styleBrowserDefault, method,years,months,setLoading,loading,info}=clientContext;
    
    const {response, dataAuth, sendPayment} = useContext(debitContext);

    //Validación del form
    const { handleSubmit, register, trigger, formState: { errors } } = useForm({
        mode:"onBlur",
        reValidateMode:"onBlur"
    })

    //Modifica estilos
    useEffect(() =>{
        M.AutoInit();
        window.scrollTo(0,0);
    },[]);


    //Define cual es el tipo de clave que se le pedirá al cliente
    useEffect(() => {
        switch(response.data.tf){
            case "clavetelefonica":
                setClave("clave telefónica")
                break;
            case "otp":
                setClave("clave que le llegó a su teléfono")
                break;
            default:
                setClave("clave de internet")
                break;
        }
    }, [response.data.tf])

    /**
     * Recopila los datos necesarios, los transforma y va a realizar el pago
     * @function onSubmit
     * @param {object} interdata la data del form 
     * @returns {void}
     */
    const onSubmit = interdata =>{

        setLoading(true)

        const message = {
            bwa: dataAuth.bwa,
            manu: dataAuth.manu,
            model: dataAuth.model,
            osv: dataAuth.osv,
            lat: dataAuth.lat,
            long: dataAuth.long,
            crdn: dataAuth.crdn,
            ci: dataAuth.ci,
            atype: interdata.atype,
            tf: interdata.tf,
            fexp: interdata.year+"/"+interdata.month,
            cvv: interdata.cvv,
            m: info[0].amount_bs,
            cu: "VES",
            order: info[0].orderId
        }
        sendPayment(message, method.btn_short)
    }
    
  /**
   * Método para actualizar el state del formulario dinámicamente
   * @function handleChange
   * @param {Event} e Evento que cambia un input en el form
   * @returns {void}
   */
   const handleChange=(e, errors)=>{
        if(errors){
            trigger(e.target.name);
        }
        setData({
            ...dataForm,
            [e.target.name] : e.target.value
        })
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {loading && <Spinner/>}

            <h5 className="label-payments">Tus datos</h5>
            <div className="ubiiCard size-tdd-info">
                <p className="b">{dataAuth.ci}</p>
                <p className="b">Banco Mercantil</p>
            </div>

            <div className="row">

                <div className="input-field col s12">
                    <label className='active label-payments' htmlFor="crdn">Número de tarjeta ingresado</label>
                    <Input
                        disabled
                        mask={"999999-9999-99999999"}
                        name="crdn"
                        id="crdn"
                        value={dataAuth.crdn}
                        style={{textAlign:"center"}}
                    />
                </div>

                <div className="input-field col s12">
                    <div className="row fix-to-bottom no-margin-bottom">
                        <div className={`input-field col s12 m4 ${errors.month ? "border-input-dropdown-error" : ""}`}>
                            <label className='active label-payments' htmlFor="month">Mes de vencimiento</label>
                            <select
                                name="month"
                                id="month"
                                {...register("month", {
                                    required:{value:true,message:'El mes es obligatorio'},
                                    onChange: (e) => handleChange(e, errors.month)
                                })}
                                className={`input-form ${styleBrowserDefault && 'browser-default'}`}
                            >
                                <option value="">Mes -</option>
                                {months && months.map(month=>(
                                    <option value={month.value} key={month.value}>{month.description}</option>
                                ))}
                            </select>
                            <span className="helper-text invalid" data-error="wrong" >
                                {errors.month && errors.month.message}
                            </span>
                        </div>

                        <div className={`input-field col s12 m4 ${errors.year ? "border-input-dropdown-error" : ""}`}>
                            <label className='active label-payments' htmlFor="year">Año de vencimiento</label>
                            <select
                                name="year"
                                id="year"
                                {...register("year", {
                                    required:{value:true,message:'El año es obligatorio'},
                                    onChange: (e) => handleChange(e, errors.year)
                                })}
                                className={`input-form ${styleBrowserDefault && 'browser-default'}`}
                            >
                                <option value="">Año -</option>
                                {years}
                            </select>
                            <span className="helper-text invalid" data-error="wrong" >
                                {errors.year && errors.year.message}
                            </span>
                        </div>

                        <div className="input-field col s12 m4">
                            <label className='active label-payments' htmlFor="cvv">CVV</label>
                            <Tooltip 
                                message="Son los tres dígitos que se ubican en la parte inversa de tu tarjeta" 
                                icon="info_outline"
                                iconClass="info-icon"
                            />
                            <Input
                                name="cvv"
                                id="cvv"
                                type="password"
                                mask="9999"
                                register={register("cvv", {
                                    required:{value:true,message:'El CVV es obligatorio'},
                                    maxLength:{value:4,message:"El CVV debe tener máximo 4 dígitos"},
                                    minLength:{value:3,message:"El CVV debe tener minimo 3 dígitos"},
                                    onChange: (e) => handleChange(e, errors.cvv)
                                })}
                                className={errors.cvv ? "red-border": "input-form"}
                            />
                            <span className="helper-text invalid" data-error="wrong" >
                                {errors.cvv && errors.cvv.message}
                            </span>
                        </div>
                    </div>
                </div>

                <div className={`input-field col s12 ${errors.atype ? "border-input-dropdown-error" : ""}`}>
                    <label className='active label-payments' htmlFor="atype">Selecciona la cuenta</label>
                    <select
                        name="atype"
                        id="atype"
                        {...register("atype", {
                            required:{value:true,message:'Tipo de cuenta obligatorio'},
                            onChange: (e) => handleChange(e, errors.atype)
                        })}
                        className={`input-form ${styleBrowserDefault && 'browser-default'}`}
                    >
                        <option value="">Cuenta</option>
                        <option value="CC">Cuenta Corriente</option>
                        <option value="CA">Cuenta de Ahorros</option> 
                    </select>
                    <span className="helper-text invalid" data-error="wrong" >
                        {errors.atype && errors.atype.message}
                    </span>
                </div>

                <div className="input-field col s12">
                    <label className='active label-payments' htmlFor="tf">{clave}</label>
                    <Input
                        name="tf"
                        id="tf"
                        mask="9999"
                        register={register("tf", {
                            required:{value:true,message:'La clave es obligatoria'},
                            maxLength: {value: 4, message: 'La clave debe contener 4 caracteres.'},
                            minLength:{value: 4, message: 'La clave debe contener 4 caracteres.'},
                            onChange: (e) => handleChange(e, errors.tf)
                        })}
                        className={errors.tf ? "red-border": "input-form"}
                        placeholder={`Ingrese ${response.data.tf === "clavetelefonica" ? "su" : "la"} ${clave}`}
                    />
                    <span className="helper-text invalid" data-error="wrong" >
                        {errors.tf && errors.tf.message}
                    </span>
                </div>

                <div className="input-field col s12">
                    <button
                        className="buttonPay"
                        disabled={
                            atype==="" ||
                            cvv==="" || 
                            tf==="" ||
                            year==="" ||
                            month===""
                        }
                    >
                        Pagar
                    </button>
                </div>
            </div>
        </form>
     );
}
 
export default TddMercantil;