import {PAGO_INTERNACIONAL, SET_DATA_CLIENT, CHARGE_AMOUNT} from '../../types';

export default (state,action) =>{

    const {type, payload} = action;

    switch (type) {

        case PAGO_INTERNACIONAL:
            return{
                ...state,
                pagoInternacional:payload,
                processPaymentInter:true
            }
        case SET_DATA_CLIENT:
            return{
                ...state,
                data_client_tdcInter: payload
            }
        case CHARGE_AMOUNT:
            return{
                ...state,
                pagoInternacional:payload,
                processChargeClient: true
            }           
        default:
            return state;
    }
 }