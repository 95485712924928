import React, { useState, useContext, useEffect } from 'react';
import { useForm } from "react-hook-form";
import Input from '../partials/Input';
import Spinner from '../spinner/Spinner';
import ClientContext from '../../context/Client/clientContext';
import M from "materialize-css";
import debitContext from '../../context/payDebit/debitContext';

const TddBVC = () => {

    //States
    const {response, dataAuth, sendPayment} = useContext(debitContext);

    const clientContext = useContext(ClientContext);
    const {method, loading, info, setLoading} = clientContext;

    const [interdata, setInter] = useState({
        token:''
    })

    const {token} = interdata;

    //Validación del form
    const { register, handleSubmit, trigger, formState: { errors } } = useForm({
        mode:"onBlur",
        reValidateMode:"onBlur"
    });

    /**
    * Método para actualizar el state del formulario dinámicamente
    * @function handleChange
    * @param {Event} e Evento que cambia un input en el form
    * @returns {void}
    */
     const handleChange=(e, errors)=>{
        if(errors){
            trigger(e.target.name);
        }
        setInter({
            ...interdata,
            [e.target.name] : e.target.value
        })
    }

    /**
     * Recopila los datos necesarios, los transforma y va a realizar el pago
     * @function onSubmit
     * @param {object} interdata la data del form 
     * @returns {void}
     */
    const onSubmit = interdata =>{

        setLoading(true)

        const message = {
            m: dataAuth.m,
            order:info[0].orderId,
            traceTCP: response.data.trace,
            idPago: response.data.idPago,
            long: dataAuth.long,
            lat: dataAuth.lat,
            token: interdata.token
        }

        sendPayment(message, method.btn_short);
    }

    //Modifica estilos
    useEffect( ()=>{
        M.AutoInit();
        window.scrollTo(0,0);
    }, [] );

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {loading && <Spinner/>}
            <div className="row">

                <div className="input-field col s12">
                    <label className='active label-payments' htmlFor="token">Token</label>
                    <Input
                        name="token"
                        id="token"
                        mask="********"
                        register={register("token", {
                            required:{value:true,message:'El token es obligatorio'},
                            maxLength: {value: 8, message: 'El token debe tener máximo 8 caracteres'},
                            minLength:{value: 4, message: 'El token debe tener mínimo 4 caracteres'},
                            onChange: (e) => handleChange(e, errors.token)
                        })}
                        className={errors.token ? "red-border": "input-form"}
                        placeholder="Ingrese aquí el token que le llegó a su teléfono:"
                    />
                    <span className="helper-text invalid" data-error="wrong" >
                        {errors.token && errors.token.message}
                    </span>
                </div>

                <div className="input-field col s12">
                    <button
                        className="buttonPay"
                        disabled={token===""}
                    >
                        ¡Listo!
                    </button>
                </div>
            </div>
        </form>
     );
}
 
export default TddBVC;