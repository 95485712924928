import React, { useReducer } from "react";
import { SET_ALERT } from "../../types";
import errorContext from "./errorContext";
import errorReducer from "./errorReducer";

const ErrorState = props => {
    
    const initialState = {
        alert: [],
        code: null
    }
    let info

    const [ state, dispatch ] = useReducer(errorReducer, initialState);

    const showError = (type) => {
        switch(type){
            case '2':
                info = ['Lo sentimos, no es posible acceder a esta página.', '/images/error_404.svg']
                break;
            case '3':
                info = ['Esta orden ya ha sido procesada. Si consideras que es un error, contacta con el comercio o vuelve a intentar.', '/images/error_cone.svg']
                break;
            case '4':
                info = ['Este botón se ha vencido, contacte con el comercio.', '/images/error_cone.svg']
                break;
            case '5':
                info = ['Este enlace no es válido, no vuelva a utilizarlo y contacte con el comercio.', '/images/error_cone.svg']
                break;
            case '6':
                info = ['Por motivos de seguridad, debe permitir la ubicación en su dispositivo si desea pagar por este método de pago.', '/images/error_cone.svg']
                break;
            case '7':
                info = ['Parece que tu navegador no permite obtener la ubicación. Por favor elija otro método de pago.', '/images/error_broken.svg']
                break;
            case '8':
                info = ['Parece que tardaste mucho en realizar tu pago. Por favor vuelve a intentar.', '/images/error_broken.svg']
                break;
            default:
                info = ['Ocurrió un problema, por favor, vuelva a intentar', '/images/error_broken.svg']
                break;
        }

        info.push(type);

        dispatch({
            type:SET_ALERT,
            payload: info
        });
    };
    
    return(
        <errorContext.Provider
            value={{
                alert: state.alert,
                code: state.code,
                showError
            }}
        >
            {props.children}
        </errorContext.Provider>
    )
}

export default ErrorState;