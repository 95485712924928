export let msg;

/**
 * Copia un elemento al portapapeles con document.execCommand('copy')
 * @function copyToClipboardOld
 * @param {string} text el texto a copiar
 * @returns {void}
 */
 export const copyToClipboardOld = (text) =>{
    var textAreaHelper = document.createElement("textarea");
    textAreaHelper.value = text;

    // Avoid scrolling to bottom
    textAreaHelper.style.top = "0";
    textAreaHelper.style.left = "0";
    textAreaHelper.style.position = "fixed";

    document.body.appendChild(textAreaHelper);
    textAreaHelper.select();

    try {
        var successful = document.execCommand('copy');
        msg = successful ? 'Se copió el texto en el portapapeles' : 'No se pudo copiar el texto';
      } catch (err) {
        msg = 'Oops, no se pudo copiar el texto en el portapapeles';
      }

    document.body.removeChild(textAreaHelper);
};

/**
 * Copia un elemento al portapapeles con navigator.clipboard
 * @function copyToClipboardPrincipal
 * @param {string} text el texto a copiar
 * @returns {void}
 */
 export const copyToClipboardPrincipal = (text) =>{
    if (!navigator.clipboard) {
        copyToClipboardOld(text);  
        return;
    }

    navigator.clipboard.writeText(text).then(function() {
        msg = 'Se copió el texto en el portapapeles';
    }, function(err) {
        msg = 'No se pudo copiar el texto por: ' + err;
    });
};