import React, { useState, useContext, useEffect, Fragment } from 'react';
import { useForm } from "react-hook-form";
import ZelleContext from '../../context/zelle/zelleContext';
import Input from '../partials/Input';
import Spinner from '../spinner/Spinner';
import ClientContext from '../../context/Client/clientContext';
import M from "materialize-css";
import { noNumbers } from '../../helpers/str';
import { InputDisplayForCurrency } from '../partials/CurrencyMask';

const Zelle = () => {

    //States
    const zelleContext= useContext(ZelleContext);
    const { sendZelle } = zelleContext;

    const clientContext = useContext(ClientContext);
    const {info, method, direction, loading, setLoading, setDirection} = clientContext;

    const [interdata, setInter] = useState({
        cod:'',
        name:'',
        ref: '',
        token: ''
    })

    const { cod, name, ref, token } = interdata;

    //Validación del form
    const { register, handleSubmit, trigger, formState: { errors } } = useForm({
        mode:'onBlur',
        reValidateMode:'onBlur'
    });

    //Pedir habilitar la ubicación para obtener latitud y longitud, sino error
    useEffect(() =>{
        if(method.btn_canal==="UBII" && direction.latitude ===""){
            let mounted = true;
            if(navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position => {
                    if(mounted){
                        setDirection(prevState => ({
                            ...prevState,
                            latitude:position.coords.latitude,
                            longitude:position.coords.longitude
                        }));
                    }       
                }), (error)=>{
                    setDirection(prevState => ({
                        ...prevState,
                        latitude:"1",
                        longitude:"1"
                    }))
                    // if(error.code === error.PERMISSION_DENIED){
                    //     showError('6');
                    //     handleError(error);
                    //     timer(7000,"Recibo");
                    // }
                });
            } else {          
                // showError('7');
                // handleError(new Error('7'));
                // timer(7000,"Recibo");
                setDirection(prevState => ({
                    ...prevState,
                    latitude:"1",
                    longitude:"1"
                }))
            }

            // Efecto por si se requiere el token
            // const callToken = async() =>{
            //     await getTokenMW();
            // }
    
            // if(method.btn_canal==="UBII" && tokenMW===undefined){
            //     callToken();
            // }
    
            return () => mounted = false;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect( ()=>{
        M.AutoInit();
        window.scrollTo(0,0);
    }, [] );

    /**
    * Método para actualizar el state del formulario dinámicamente
    * @function handleChange
    * @param {Event} e Evento que cambia un input en el form
    * @returns {void}
    */
     const handleChange=(e, errors)=>{
        if(errors){
          trigger(e.target.name);
        }
        setInter({
            ...interdata,
            [e.target.name] : e.target.value
        })
    }

    /**
     * Recopila los datos necesarios, los transforma y va a realizar el pago
     * @function onSubmit
     * @param {object} interdata la data del form 
     * @returns {void}
     */
    const onSubmit = interdata =>{

        setLoading(true)
        let message;
        let provider;

        if(method.btn_canal==="UBII"){
            message = {
                m: info[0].amount_ds,
                name: interdata.name,
                lat: direction.latitude.toString(),
                long: direction.longitude.toString(),
                token: interdata.token,
                order: info[0].orderId,
                ref: interdata.ref
            };
            provider = "_ubii_zelle_utu";
        }else if(method.btn_canal==="N247"){
            message = {
                cod: interdata.cod,
                m: info[0].amount_ds,
                order: info[0].orderId
            }
            provider="_zelle";
        }

        sendZelle(message, provider);
    }

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="margin-top-08 col s12 l12 titles-2 center-align">
                <h5 >Información del pago</h5>
            </div>
            {loading && <Spinner/>}
            {method.btn_canal==="UBII" && direction.latitude ==="" && <Spinner/>}
            <div className="row">
                <div className="input-field col s12 center-align">
                    <div className="center-align">
                        <img src="/images/icons/checker.svg" alt="chequera"/>
                    </div>
                    <label className='active label-payments' htmlFor="totalAmount">Verifica y toma nota del monto a pagar en dólares.</label>
                        <InputDisplayForCurrency 
                            amount={info[0].amount_ds}
                            insideForm
                        />
                </div>

                <div className="input-field col s12 center-align">
                    <div className="center-align">
                        <img src="/images/icons/phone-pay.svg" alt="correo"/>
                    </div>
                    <label className='active label-payments' htmlFor="email">Realiza el zelle con el correo presentado a continuación.</label>
                    <Input
                        disabled
                        className="text-center"
                        name="email"
                        id="email"
                        value={method.zelle_info}
                        iconRight="content_copy"
                    />
                </div>

                {method.btn_canal==="UBII" &&
                    (
                        <Fragment>
                            <div className="input-field col s12 center-align">
                                <div className="center-align">
                                    <img src="/images/icons/phone-pay.svg" alt="token"/>
                                </div>
                                <label className='active label-payments' htmlFor="token">Ingresa el token de tu compra.</label>
                                <Input
                                    name="token"
                                    id="token"
                                    mask="**********"
                                    placeholder="Ingrese aquí el token"
                                    className={errors.token ? "red-border": "input-form"}
                                    register={register("token", {
                                        required:{value:true,message:'El token es requerido'},
                                        maxLength:{value:20,message:"El token debe tener máximo 20 caracteres"},
                                        // minLength:{value:8,message:"El token debe tener mínimo 8 caracteres"},
                                        onChange: (e) => handleChange(e, errors.token)
                                    })}
                                />
                                <span className="helper-text invalid" data-error="wrong" >
                                    {errors.token && errors.token.message}
                                </span>
                            </div>
                        
                            <div className="input-field col s12 center-align">
                                <label className='active label-payments' htmlFor="name">Nombre y apellido</label>
                                <Input
                                    name="name"
                                    id="name"
                                    maxLength="40"
                                    register={register("name", {
                                        required:{value:true,message:'El nombre es requerido'},
                                        maxLength:{value:40,message:"El nombre debe tener máximo 40 caracteres"},
                                        validate: value => noNumbers(value) || 'El nombre no es válido',
                                        onChange: (e) => handleChange(e, errors.name)
                                    })}
                                    placeholder="Ingrese aquí su nombre completo"
                                    className={errors.name ? "red-border": "input-form"}
                                />
                                <span className="helper-text invalid" data-error="wrong" >
                                    {errors.name && errors.name.message}
                                </span>
                            </div>

                            <div className="input-field col s12 center-align">
                                <label className='active label-payments' htmlFor="ref">Referencia del Banco</label>
                                <Input
                                    name="ref"
                                    id="ref"
                                    maxLength="40"
                                    register={register("ref", {
                                        required:{value:true,message:'La referencia es requerida'},
                                        minLength: {value: 5, message: 'La referencia debe tener al menos 5 caracteres'},
                                        onChange: (e) => handleChange(e, errors.ref)
                                    })}
                                    placeholder="Ingrese aquí la referencia del banco"
                                    className={errors.ref ? "red-border": "input-form"}
                                />
                                <span className="helper-text invalid" data-error="wrong" >
                                    {errors.ref && errors.ref.message}
                                </span>
                            </div>
                        </Fragment>
                    )
                }

                {method.btn_canal==="N247" && (
                    <div className="input-field col s12 center-align">
                        <div className="center-align">
                            <img src="/images/icons/ref.svg" alt="referencia"/>
                        </div>
                        <label className='active label-payments' htmlFor="cod">Registra los últimos 5 dígitos de la referencia del pago y ¡listo!</label>
                        <Input
                            name="cod"
                            id="cod"
                            mask="**********"
                            register={register("cod", {
                                required:{value:true,message:'El número de referencia es obligatorio'},
                                maxLength: {value: 20, message: 'El número de referencia no puede exceder los 20 caracteres'},
                                minLength: {value: 5, message: 'El número de referencia debe tener al menos 5 caracteres'},
                                onChange: (e) => handleChange(e, errors.cod)
                            })}
                            placeholder="Ingresa la referencia"
                            className={errors.cod ? "red-border": "input-form"}
                            autoComplete="off"
                        />
                        <span className="helper-text invalid" data-error="wrong" >
                            {errors.cod && errors.cod.message}
                        </span>
                    </div>
                )}
                
                <div className="input-field col s12">
                    <button
                        className="buttonPay"
                        disabled={
                            (method.btn_canal==="UBII" && (name==="" || ref==="" || token==="")) || 
                            (method.btn_canal==="N247" && cod==="")
                        }
                    >
                        ¡Listo!
                    </button>
                </div>
            </div>
        </form>
     );
}
 
export default Zelle;