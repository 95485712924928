import React,{useReducer, useContext} from 'react';
import debitContext from './debitContext';
import mercantilReducer from './debitReducer';
import {PAGAR_DEBITO, SET_DATA_CLIENT, DEBIT_AUTH, SET_DATA_AUTH, SET_OPTIONS} from '../../types';
import ClientContext from '../Client/clientContext';
import errorContext from '../Error/errorContext';

const DebitState= props=>{

    const clientContext=useContext(ClientContext);
    const {setLoading, method, handleError, pay}=clientContext;
    const { showError } = useContext(errorContext);

    const initialState={
        dataAuth:null,
        data_client_tdd:null,
        processPaymentDebit:false,
        processAuth:null,
        dataDebit:undefined,
        response: null,
        months:[
            {'value':'1','description':'Enero'},
            {'value':'2','description':'Febrero'},
            {'value':'3','description':'Marzo'},
            {'value':'4','description':'Abril'},
            {'value':'5','description':'Mayo'},
            {'value':'6','description':'Junio'},
            {'value':'7','description':'Julio'},
            {'value':'8','description':'Agosto'},
            {'value':'9','description':'Septiembre'},
            {'value':'10','description':'Octubre'},
            {'value':'11','description':'Noviembre'},
            {'value':'12','description':'Diciembre'}
        ],
        options:undefined
    }

    //Dispatch para ejecutar las acciones
    const [state, dispatch] = useReducer(mercantilReducer, initialState)

    /**
     * Envía datos al endpoint de Mercantil Auth. Coloca en el contexto la respuesta, o el error si hubo alguno 
     * @async
     * @function sendToAuth
     * @param {object} datos información enviada desde el formulario de tdd auth
     * @returns {void}
     */
    const sendToAuth= async (datos, bank) =>{
        try {
            dispatch({
                type: SET_DATA_AUTH,
                payload:datos
            })
            
            const response = await pay(bank ==="bvc" ? 'payment_venezolano_debit' : `payment_${bank}_auth`, datos, method.btn_key);

            dispatch({
                type:DEBIT_AUTH,
                payload:response
            })
            setLoading(false)
            
        } catch (error) {
            console.log(error);
            showError('1');
            handleError(error);
            setLoading(false);
        }
    }

    /**
     * Envía datos al endpoint final de Mercantil. Coloca en el contexto el resultado de la transacción, o el error si hubo alguno 
     * @async
     * @function sendPayment
     * @param {object} datos información enviada desde el formulario de tdd
     * @returns {void}
     */
    const sendPayment = async (datos, bank) => {
        try{
            dispatch({
                type: SET_DATA_CLIENT,
                payload:datos
            })
            
            const response = await pay(bank ==="bvc" ? 'payment_venezolano_debit_confirm' : `payment_${bank}_tdd`, datos, method.btn_key);

            dispatch({
                type:PAGAR_DEBITO,
                payload:response
            })
            setLoading(false)

        }catch (error){
            console.log(error)
        }
    }

    /**
     * Coloca las opciones que irán al formulario de auth con las que cambiará dinámicamente el formulario 
     * @async
     * @function setOptions
     * @param {string} bank el código del banco seleccionado por el usuario
     * @returns {void}
     */
    const setOptions = (bank) => {
        let opt = {};
        switch(bank){
            case "0102":
                opt = {
                    phoneP: true,
                    email: true,
                }
                break;
            case "0105":
                opt = {
                    crdn: true
                }
                break;
            case "0104":
                opt = {
                    phoneP: true,
                    email:true,
                    account:true,
                    name:true
                }
                break;
            default:
                opt = {
                    bank: true
                }
                break;
        }

        dispatch({
            type: SET_OPTIONS,
            payload: opt
        })
    }

    return (
        <debitContext.Provider
            value={{
                response: state.response,
                dataAuth: state.dataAuth,
                data_client_tdd: state.data_client_tdd,
                processPaymentDebit:state.processPaymentDebit,
                months:state.months,
                dataDebit: state.dataDebit,
                processAuth: state.processAuth,
                options: state.options,
                sendToAuth,
                sendPayment,
                setOptions
            }}
        >
            {props.children}
        </debitContext.Provider>
    )
}
export default DebitState;