import React, { useState, useContext, useEffect } from 'react';
import { useForm } from "react-hook-form";
import ubiiAppContext from '../../context/ubiiApp/ubiiAppContext';
import Spinner from '../spinner/Spinner';
import ClientContext from '../../context/Client/clientContext';
import M from "materialize-css";
import { InputDisplayForCurrency } from '../partials/CurrencyMask';
import PhoneP from "../partials/formInputs/phoneP";

const UbiiApp = () => {

    //States
    const ubiiContext = useContext(ubiiAppContext);
    const { sendUbii } = ubiiContext;

    const clientContext = useContext(ClientContext);
    const {selectedCoin, loading, info, direction, setDirection, setLoading} = clientContext;

    const [interdata, setInter] = useState({
        phoneP: ""
    })

    const {phoneP} = interdata;

    //Validación del form
    const { register, handleSubmit, trigger, formState: { errors } } = useForm({
        mode:"onBlur",
        reValidateMode:"onBlur"
    });

    /**
    * Método para actualizar el state del formulario dinámicamente
    * @function handleChange
    * @param {Event} e Evento que cambia un input en el form
    * @returns {void}
    */
     const handleChange=(e, errors)=>{
        if(errors){
            trigger(e.target.name);
        }
        if(e.target.name==="phoneP" && e.target.value==="+58 ("){
            e.target.value=""
        }
        setInter({
            ...interdata,
            [e.target.name] : e.target.value
        })
    }

    /**
     * Recopila los datos necesarios, los transforma y va a realizar el pago
     * @function onSubmit
     * @param {object} interdata la data del form 
     * @returns {void}
     */
    const onSubmit = interdata =>{

        setLoading(true)

        const message = {
            rif: info[2].rif_comercio,
            cp: "Cobro BTN",
            lat: direction.latitude.toString(),
            long: direction.longitude.toString(),
            m: selectedCoin==="ds" ? info[0].amount_ds : info[0].amount_bs,
            cu: selectedCoin==="ds" ? 'USD' : 'VES',
            order: info[0].orderId,
            telf: interdata.phoneP.replace(" (", "-").replace(/[\s)]/g, "")
        }

        sendUbii(message);
    }

    //Modifica estilos
    useEffect( ()=>{
        M.AutoInit();
        window.scrollTo(0,0);
    }, [] );

    //Pedir habilitar la ubicación para obtener latitud y longitud, sino error
    useEffect(() =>{
        if(direction.latitude ===""){
            let mounted = true;
            if(navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position => {
                    if(mounted){
                        setDirection(prevState => ({
                            ...prevState,
                            latitude:position.coords.latitude,
                            longitude:position.coords.longitude
                        }));
                    }
                }), (error)=>{
                    setDirection(prevState => ({
                        ...prevState,
                        latitude:"1",
                        longitude:"1"
                    }))
                    // if(error.code === error.PERMISSION_DENIED){
                    //     showError('6');
                    //     handleError(error);
                    //     timer(7000,"Recibo");
                    // }
                });
            } else {
                // showError('7');
                // handleError(new Error('7'));
                // timer(7000,"Recibo");
                setDirection(prevState => ({
                    ...prevState,
                    latitude:"1",
                    longitude:"1"
                }))
            }
            return () => mounted = false;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return ( 
        <>
            {direction.latitude ==="" && <Spinner/>}
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="margin-top-08 col s12 l12 titles-3 center-align">
                    <h5 >Vas a pagar con <span>UbiiApp</span> un monto en Bs. de:</h5>
                </div>
                {loading && <Spinner/>}
                <div className="row">

                    <div className="input-field col s12">
                        <InputDisplayForCurrency 
                            amount={info[0].amount_bs}
                            insideForm
                        />
                    </div>

                    <PhoneP
                        register={register("phoneP", {
                        required:{value:true,message:'El número telefónico del cliente es obligatorio'},
                        minLength:{value:19,message:"Coloque un número telefónico válido"},
                        onChange: (e) => handleChange(e, errors.phoneP)
                        })}
                        errors={errors.phoneP}
                    />

                    <div className="input-field col s12">
                        <button
                            className="buttonPay"
                            disabled={phoneP===""}
                        >
                            ¡Listo!
                        </button>
                    </div>
                </div>
            </form>
        </>
     );
}
 
export default UbiiApp;