import React,{useState, useEffect, useContext, Fragment} from 'react';
import M from "materialize-css";
import { useForm, Controller } from "react-hook-form";
import Input from '../partials/Input';
import PayContext from '../../context/Pay/payContext';
import ClientContext from '../../context/Client/clientContext';
import {uppercase, removeNonDigits, validateCard, validateRif, noNumbers} from '../../helpers/str';
import Spinner from '../spinner/Spinner';
import UAParser from "ua-parser-js";
import Tooltip from '../partials/Tooltip';
import { InputDisplayForCurrency } from '../partials/CurrencyMask';
import { customStyles } from '../internacional/TdcInternacional';
import Select from 'react-select';

const Tdc = () => {

    const initialState = {
        card:'',
        month:'',
        year:'',
        cvv:'',
        dni:'',
        letter:'V',
        name:'',
        isInternational:false,
        zip:'',
        country:'',
        city:'',
        state:'',
        dir:''
    }

    //States
    const[interData, guardarInter]=useState(initialState);
    const {
        card, month, year, cvv, dni, letter, name, isInternational,
        zip, country, city, state, dir
    } = interData;

    const payContext=useContext(PayContext);
    const {sendPayment}=payContext;

    const clientContext = useContext(ClientContext);
    const { styleBrowserDefault, method, direction, years, months, loading, countries, setLoading, info, setDirection} = clientContext;

    //Validación del form
    const { register, handleSubmit, trigger, control, formState: { errors } } = useForm({
        mode:"onBlur",
        reValidateMode:"onBlur",
        shouldUnregister: true
    });

  /**
   * Método para actualizar el state del formulario dinámicamente
   * @function handleChange
   * @param {Event} e Evento que cambia un input en el form
   * @returns {void}
   */
    const handleChange=(e, errors)=>{
        if(errors){
            trigger(e.target.name);
        }
        if(e.label){
            guardarInter({
                ...interData,
                country: e.label
            })
        }else{
            if(e.target.name==="name"){
                e.target.value=uppercase(e.target.value)
            }
            guardarInter({
                ...interData,
                [e.target.name]:e.target.value
            })
        }
    }

    useEffect(() => {
        M.AutoInit();
    }, [isInternational])

    //Pedir habilitar la ubicación para obtener latitud y longitud, sino error
    useEffect(() =>{
        window.scrollTo(0,0);
        if(direction.latitude ===""){
            let mounted = true;
            if(navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position => {
                    if(mounted){
                        setDirection(prevState => ({
                            ...prevState,
                            latitude:position.coords.latitude,
                            longitude:position.coords.longitude
                        }));
                    }       
                }), (error)=>{
                    setDirection(prevState => ({
                        ...prevState,
                        latitude:"1",
                        longitude:"1"
                    }))
                    // if(error.code === error.PERMISSION_DENIED){
                    //     showError('6');
                    //     handleError(error);
                    //     timer(7000,"Recibo");
                    // }
                });
            } 
            else {
                // showError('7');
                // handleError(new Error('7'));
                // timer(7000,"Recibo");
                setDirection(prevState => ({
                    ...prevState,
                    latitude:"1",
                    longitude:"1"
                }))
            }

            return () => mounted = false;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    /**
     * Recopila los datos necesarios, los transforma y va a realizar el pago
     * @function onSubmit
     * @param {object} interdata la data del form 
     * @returns {void}
     */
    const onSubmit= interdata=>{
        setLoading(true)

        var parser = new UAParser();
        var res = parser.getResult();
        var message = {
            crdn: removeNonDigits(interdata.card),
            ncrd:uppercase(interdata.name),
            cvv: interdata.cvv,
            lat: direction.latitude.toString() || "N/A",
            long: direction.longitude.toString() || "N/A",
            order: info[0].orderId,
            isInternational: isInternational,
            origin: "BVC",
            cu: "VES",
            m: info[0].amount_bs,
            bwa: res.browser.name,
            manu: res.device.vendor === undefined ? "N/A" : res.device.vendor,
            model: res.device.model === undefined ? "N/A" : res.device.model,
            osv: res.os.version,
            ci: !isInternational ? interdata.letter+""+interdata.dni : "J407586980",
            fexp:  method.btn_canal === "0104" ? `${interdata.month}-${interdata.year.slice(2)}` : interdata.year+"/"+interdata.month,
        }

        if(isInternational){
            //message.fexp = interdata.month +""+interdata.year.slice(2);
            message.zip = interdata.zip;
            message.dir = `${interdata.dir}, ${interdata.city}, ${interdata.state}, ${interdata.country}`;
        }
        sendPayment(message);
    }

    return ( 
        <>
            {direction.latitude ==="" && <Spinner/>}
            <form 
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className="margin-top-08 col s12 l12 titles-3 center-align">
                    <h5 >Vas a pagar con <span>tarjeta de crédito</span> un monto en Bs. de:</h5>
                </div>
                {loading && <Spinner/>}
                <div className="row">
                    <div className="input-field col s12">
                        <InputDisplayForCurrency 
                            amount={info[0].amount_bs}
                            insideForm
                        />
                    </div>

                    <div className="input-field col s12">
                        <label className='active label-payments' htmlFor="name">Nombre del titular de la tarjeta</label>
                        <Input
                            name="name"
                            id="name"
                            maxLength="40"
                            register={register("name", {
                                required:{value:true,message:'Nombre del tarjeta habiente es requerido'},
                                maxLength:{value:40,message:"este campo debe tener máximo 40 caracteres"},
                                validate: value => noNumbers(value) || 'Nombre del tarjeta habiente no válido',
                                onChange: (e) => handleChange(e, errors.name)
                            })}
                            placeholder="Nombre del titular que aparece en la tarjeta"
                            className={errors.name ? "red-border": "input-form"}
                        />
                        <span className="helper-text invalid" data-error="wrong" >
                            {errors.name && errors.name.message}
                        </span>
                    </div>

                    <div className="input-field col s12 center-align">
                        <p>
                            <label>
                                <input
                                    type="checkbox"
                                    id="isInternational"
                                    name="isInternational"
                                    {...register("isInternational", {
                                        onChange: (e) => guardarInter({
                                            ...interData,
                                            [e.target.name]:e.target.checked
                                        })
                                    })}
                                />
                                <span className="active label-payments custom-checkbox">¿Tu tarjeta es internacional?</span>
                            </label>
                        </p>
                        <span className="helper-text invalid" data-error="wrong" ></span>
                    </div>

                    {!isInternational && (
                        <Fragment>
                            <div className="col s12 label-dni">
                                <label className='active label-payments' htmlFor="letter">Datos de tu documento de identidad</label>
                            </div>
                            <div className={`input-field col s8 offset-s2 m3 offset-m0 center-align no-margin-bottom`}>
                                <select
                                    name="letter"
                                    id="letter"
                                    {...register("letter", {
                                        required:{value:true,message:'La nacionalidad es obligatoria'},
                                        onChange: (e) => handleChange(e, errors.letter)
                                    })}
                                    className={`input-form ${styleBrowserDefault && 'browser-default'}`}
                                    autoComplete="off"
                                >
                                    <option value="V">V</option>
                                    <option value="E">E</option>
                                    <option value="J">J</option>
                                    <option value="G">G</option>
                                    <option value="C">C</option>
                                </select>
                            </div>

                            <div className="input-field col s12 m9">
                                <Input
                                    name="dni"
                                    id="dni"
                                    mask="999999999" 
                                    register={register("dni", {
                                        required:{value:true,message:'Este campo es obligatorio'},
                                        validate: value => validateRif(value, letter) || "El RIF no es válido",
                                        ...(letter==="J" ?
                                            {minLength:{value:9,message:"Este campo debe tener 9 caracteres"}} : 
                                            {minLength:{value:6,message:"Este campo debe tener al menos 6 caracteres"}}
                                        ),
                                        onChange: (e) => handleChange(e, errors.dni)
                                    })}
                                    placeholder="Cédula/RIF"
                                    className={errors.dni ? "red-border": "input-form"}
                                />
                                <span className="helper-text invalid" data-error="wrong" >
                                    {errors.dni && errors.dni.message}
                                </span>
                            </div>
                        </Fragment>
                    )}

                    <div className="input-field col s12">
                        <label className='active label-payments' htmlFor="card">Número de tarjeta</label>
                        <Input
                            name="card" 
                            id="card"
                            mask={isInternational ? "9999-9999-9999-99999" : "9999-9999-9999-9999"}
                            register={register("card", {
                                required:{value:true,message:'Número de tarjeta es obligatorio'},
                                minLength:{value: 14, message:'Número de tarjeta debe tener mínimo 14 dígitos'},
                                validate: value => validateCard(value) || 'Número de tarjeta no válido',
                                onChange: (e) => handleChange(e, errors.card)
                            })}
                            placeholder="Ingresa tu número de tarjeta"
                            className={errors.card ? "red-border": "input-form"}
                        />
                        <span className="helper-text invalid" data-error="wrong" >
                            {errors.card && errors.card.message}
                        </span>
                    </div>

                    <div className="input-field col s12">
                        <div className="row fix-to-bottom no-margin-bottom">
                            <div className={`input-field col s12 m4 ${errors.month ? "border-input-dropdown-error" : ""}`}>
                                <label className='active label-payments' htmlFor="month">Mes de vencimiento</label>
                                <select
                                    name="month"
                                    id="month"
                                    {...register("month", {
                                        required:{value:true,message:'El mes es obligatorio'},
                                        onChange: (e) => handleChange(e, errors.month)
                                    })}
                                    className={`input-form ${styleBrowserDefault && 'browser-default'}`}
                                >
                                    <option value="">Mes -</option>
                                    {months && months.map(month=>(
                                        <option value={month.value} key={month.value}>{month.description}</option>
                                    ))}
                                </select>
                                <span className="helper-text invalid" data-error="wrong" >
                                    {errors.month && errors.month.message}
                                </span>
                            </div>

                            <div className={`input-field col s12 m4 ${errors.year ? "border-input-dropdown-error" : ""}`}>
                                <label className='active label-payments' htmlFor="year">Año de vencimiento</label>
                                <select
                                    name="year"
                                    id="year"
                                    {...register("year", {
                                        required:{value:true,message:'El año es obligatorio'},
                                        onChange: (e) => handleChange(e, errors.year)
                                    })}
                                    className={`input-form ${styleBrowserDefault && 'browser-default'}`}
                                >
                                    <option value="">Año -</option>
                                    {years}
                                </select>
                                <span className="helper-text invalid" data-error="wrong" >
                                    {errors.year && errors.year.message}
                                </span>
                            </div>

                            <div className="input-field col s12 m4">
                                <label className='active label-payments' htmlFor="cvv">CVV</label>
                                <Tooltip 
                                    message="Son los tres dígitos que se ubican en la parte inversa de tu tarjeta" 
                                    icon="info_outline"
                                    iconClass="info-icon"
                                />
                                <Input
                                    name="cvv"
                                    id="cvv"
                                    type="password"
                                    mask="9999"
                                    register={register("cvv", {
                                        required:{value:true,message:'El cvv es obligatorio'},
                                        maxLength:{value:4,message:"El cvv debe tener máximo 4 dígitos"},
                                        minLength:{value:3,message:"El cvv debe tener minimo 3 dígitos"},
                                        onChange: (e) => handleChange(e, errors.cvv)
                                    })}
                                    className={errors.cvv ? "red-border": "input-form"}
                                />
                                <span className="helper-text invalid" data-error="wrong" >
                                    {errors.cvv && errors.cvv.message}
                                </span>
                            </div>
                        </div>
                    </div>

                    {isInternational && (
                        <Fragment>
                            <div className="input-field col s12 center-align">
                                <div className="center-align">
                                    <img src="/images/icons/address.svg" alt="dirección"/>
                                    <div className="ubiiGrey">Dirección de facturación</div>
                                </div>
                            </div>
                
                            <div className="input-field col s12">
                                <label className='active label-payments' htmlFor="country">&#10991; País</label>
                                <Controller
                                    id="country-drop"
                                    control={control}
                                    name="country"
                                    autoComplete="off"
                                    rules={{required:{value:true,message:'El país es requerido'}}}
                                    render={({ field: { onChange } }) => (
                                        <Select
                                            options={countries}
                                            components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                                            styles={customStyles}
                                            classNamePrefix="react-select"
                                            noOptionsMessage={() => 'País no encontrado'}
                                            placeholder="Ingresa el país"
                                            onChange={(e) => {
                                                onChange(e.label);
                                                e.target={name: "country"};
                                                handleChange(e, errors.country);
                                            }}
                                            autoComplete="off"
                                        />
                                    )}
                                />
                                <span className="helper-text invalid" data-error="wrong" >
                                    {errors.country && errors.country.message}
                                </span>
                            </div>
                
                            <div className="input-field col s12">
                                <label className='active label-payments' htmlFor="city">&#10991; Ciudad</label>
                                <Input
                                    name="city" 
                                    id="city" 
                                    maxLength="45"
                                    register={register("city", {
                                        required:{value:true,message:'La ciudad es obligatoria'},
                                        validate: value => noNumbers(value) || 'La ciudad no es válida',
                                        onChange: (e) => handleChange(e, errors.city)
                                    })}
                                    className={errors.city ? "red-border": "input-form"}
                                    placeholder="Ingresa la ciudad"
                                />
                                <span className="helper-text invalid" data-error="wrong" >
                                    {errors.city && errors.city.message}
                                </span>
                            </div>
                
                            <div className="input-field col s12">
                                <label className='active label-payments' htmlFor="state">&#10991; Estado</label>
                                <Input
                                    name="state" 
                                    id="state"
                                    maxLength="45"
                                    register={register("state", {
                                        required:{value:true,message:'El estado es requerido'},
                                        validate: value => noNumbers(value) || 'El estado no es válido',
                                        onChange: (e) => handleChange(e, errors.state)
                                    })}
                                    className={errors.state ? "red-border": "input-form"}
                                    placeholder="Ingresa el estado"
                                />
                                <span className="helper-text invalid" data-error="wrong" >
                                    {errors.state && errors.state.message}
                                </span>
                            </div>
                
                            <div className="input-field col s12">
                                <label className='active label-payments' htmlFor="dir">&#10991; Dirección (Casa, Avenida, Calle)</label>
                                <Input
                                    name="dir" 
                                    id="dir"
                                    register={register("dir", {
                                        required:{value:true,message:'La dirección es obligatoria'},
                                        onChange: (e) => handleChange(e, errors.dir)
                                    })}
                                    className={errors.dir ? "red-border": "input-form"}
                                    placeholder="Ingresa la dirección"
                                />
                                <span className="helper-text invalid" data-error="wrong" >
                                    {errors.dir && errors.dir.message}
                                </span>
                            </div>
                
                            <div className="input-field col s12">
                                <label className='active label-payments' htmlFor="zip">&#10991; Código Postal</label>
                                <Input
                                    name="zip"
                                    id="zip"
                                    maxLength="10"
                                    register={register("zip", {
                                        required:{value:true,message:'El zip es requerido'},
                                        onChange: (e) => handleChange(e, errors.zip)
                                    })}
                                    className={errors.zip ? "red-border": "input-form"}
                                    placeholder="Ingresa el código postal"
                                />
                                <span className="helper-text invalid" data-error="wrong" >
                                    {errors.zip && errors.zip.message}
                                </span>
                            </div>
                        </Fragment>
                    )}

                    <div className="input-field col s12">
                        <button
                            className="buttonPay"
                            disabled={
                                isInternational ? 
                                (
                                    cvv==="" ||
                                    card==="" ||
                                    year==="" ||
                                    month==="" ||
                                    name==="" ||
                                    zip==="" || 
                                    country==="" || 
                                    city ==="" || 
                                    state==="" || 
                                    dir===""
                                ) :
                                (
                                    cvv==="" ||
                                    card==="" ||
                                    year==="" ||
                                    month==="" ||
                                    name==="" ||
                                    dni===""
                                )
                            }
                        >
                            ¡Listo!
                        </button>
                    </div>
                </div>
            </form>
        </>
    );
}
 
export default Tdc;