import { SET_ALERT } from "../../types";

export default (state, action) => {
    
    const {type, payload} = action;
    switch(type) {
        case SET_ALERT:
            return{
                alert: payload,
                code: payload[2]
            }
        default:
            return state;
    }
}