import React, { useReducer, useContext } from "react";
import internacionalContext from "./internacionalContext";
import internacionalReducer from "./internacionalReducer";
import {
  PAGO_INTERNACIONAL,
  SET_DATA_CLIENT,
  CHARGE_AMOUNT
} from "../../types";
import ClientContext from '../Client/clientContext';
import errorContext from "../Error/errorContext";

const InternacionalState = (props) => {

  //States
  const initialState = {
    pagoInternacional: "",
    processPaymentInter: false,
    processChargeClient: false,
    data_client_tdcInter:null
  };

  const clientContext=useContext(ClientContext);
  const {setLoading, method, handleError, pay, nextStep}=clientContext;
  const { showError } = useContext(errorContext);

  //Dispatch para ejecutar las acciones
  const [state, dispatch] = useReducer(internacionalReducer, initialState);

  /**
   * Envía datos al endpoint final de pago internacional. Coloca en el contexto el resultado de la transacción, o el error si hubo alguno 
   * @async
   * @function sendInternacional
   * @param {object} datos información enviada desde el formulario de tdc internacional
   * @returns {void}
   */
  const sendInternacional = async (datos) => {
    try {
      dispatch({
        type: SET_DATA_CLIENT,
        payload: datos
      });
      const response = await pay('payment_authorization', datos, method.btn_key);
      //Primera vez que debe poner el monto o tarjeta en proceso de activación va a poner monto
      if((response.data.R === "0" && response.data.codR==="80") || (response.data.R === "1" && response.data.codR==="999")){
        dispatch({
          type: CHARGE_AMOUNT,
          payload: response
        });
        nextStep(3);
      //Tarjeta bloqueada, transacción aprobada o transacción negada para factura
      }else{
        dispatch({
          type: PAGO_INTERNACIONAL,
          payload: response
        });
      }

      setLoading(false);
     
    } catch (error) {
      console.log(error);
      showError('1');
      handleError(error);
      setLoading(false)
    }
  };

    /**
   * Setea la variable processPaymentInter del contexto y los datos de la transacción
   * @function setProcessPaymentInter
   * @param {object} resp información enviada desde internacionalMicroC
   * @returns {void}
   */
    const setProcessPaymentInter = (resp) =>{
      dispatch({
        type: PAGO_INTERNACIONAL,
        payload: resp
      });
    }
  
  return (
    <internacionalContext.Provider
      value={{
        pagoInternacional: state.pagoInternacional,
        processPaymentInter: state.processPaymentInter,
        data_client_tdcInter: state.data_client_tdcInter,
        processChargeClient: state.processChargeClient,
        sendInternacional,
        setProcessPaymentInter
      }}
    >
      {props.children}
    </internacionalContext.Provider>
  );
};

export default InternacionalState;
