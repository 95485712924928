import React, { useState, useContext, useEffect } from 'react';
import debitContext from '../../context/payDebit/debitContext';
import { useForm } from 'react-hook-form';
import Spinner from '../spinner/Spinner';
import ClientContext from '../../context/Client/clientContext';
import Input from '../partials/Input';
import M from "materialize-css";

const TddVenezuela = () => {

    //States
    const [dataForm, setData] = useState({
        token:'',
        ta:''
    });

    const { token, ta } = dataForm;

    const clientContext=useContext(ClientContext);
    const { styleBrowserDefault, method, setLoading, loading, info }=clientContext;
    
    const {response, dataAuth, sendPayment} = useContext(debitContext);


    //Validación del form
    const { handleSubmit, register, trigger, formState: { errors } } = useForm({
        mode:"onBlur",
        reValidateMode:"onBlur"
    });

    //Modifica estilos
    useEffect(() =>{
        M.AutoInit();
        window.scrollTo(0,0);
    },[]);

    /**
     * Recopila los datos necesarios, los transforma y va a realizar el pago
     * @function onSubmit
     * @param {object} interdata la data del form 
     * @returns {void}
     */
    const onSubmit = interdata =>{

        setLoading(true)

        const message = {
            m: info[0].amount_bs,
            cu: dataAuth.cu,
            ci: dataAuth.ci,
            phoneP: dataAuth.phoneP,
            email: dataAuth.email,
            paymentId: response.data.paymentId,
            order: info[0].orderId,
            token: interdata.token,
            ta: interdata.ta
        }
        sendPayment(message, method.btn_short)
    }
    
  /**
   * Método para actualizar el state del formulario dinámicamente
   * @function handleChange
   * @param {Event} e Evento que cambia un input en el form
   * @returns {void}
   */
   const handleChange=(e, errors)=>{
        if(errors){
            trigger(e.target.name);
        }
        setData({
            ...dataForm,
            [e.target.name] : e.target.value
        })
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {loading ? <Spinner/> :null}
            
            <h5 className="label-payments">Tus datos</h5>
            <div className="ubiiCard size-tdd-info">
                <p className="b">{dataAuth.ci}</p>
                <p className="b">Banco de Venezuela</p>
            </div>

            <div className="row">

                <div className={`input-field col s12 ${errors.ta ? "border-input-dropdown-error" : ""}`}>
                    <label className='active label-payments' htmlFor="ta">Selecciona la cuenta</label>
                    <select
                        name="ta"
                        id="ta"
                        {...register("ta", {
                            required:{value:true,message:'Tipo de cuenta obligatorio'},
                            onChange: (e) => handleChange(e, errors.ta)
                        })}
                        className={`input-form ${styleBrowserDefault && 'browser-default'}`}
                    >
                        <option value="">Cuenta</option>
                        <option value="2">Cuenta Corriente</option>
                        <option value="1">Cuenta de Ahorros</option> 
                    </select>
                    <span className="helper-text invalid" data-error="wrong" >
                        {errors.ta && errors.ta.message}
                    </span>
                </div>

                <div className="input-field col s12">
                    <label className='active label-payments' htmlFor="token">Ingresa el código que se envió a tu correo</label>
                    <Input
                        name="token"
                        id="token"
                        mask="***********"
                        register={register("token", {
                            required:{value:true,message:'El token es obligatorio'},
                            minLength:{value: 6, message: 'El token debe contener al menos 6 caracteres.'},
                            onChange: (e) => handleChange(e, errors.token)
                        })}
                        className={errors.token ? "red-border": "input-form"}
                        placeholder="Ingresa el código que se te envió"
                    />
                    <span className="helper-text invalid" data-error="wrong" >
                        {errors.token && errors.token.message}
                    </span>
                </div>
                
                <div className="input-field col s12">
                    <button
                        className="buttonPay"
                        disabled={
                            token==="" ||
                            ta===""
                        }
                    >
                        Pagar
                    </button>
                </div>
            </div>
        </form>
     );
}
 
export default TddVenezuela;