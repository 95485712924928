import React, { useState, useEffect, useContext } from "react";
import M from "materialize-css";
import { useForm } from "react-hook-form";
import Spinner from "../spinner/Spinner";
import { emailValidator, isBolivarAccount, noNumbers, pad, removeNonDigits, validateCard, validateRif } from "../../helpers/str";
import debitContext from '../../context/payDebit/debitContext';
import Input from "../partials/Input";
import UAParser from "ua-parser-js";
import ClientContext from '../../context/Client/clientContext';
import { InputDisplayForCurrency } from "../partials/CurrencyMask";
import PhoneP from "../partials/formInputs/phoneP";
import Email from "../partials/formInputs/email";

const TddAuth = ({options}) => {

  //States
  const [dataAuth, setDataauth] = useState({
    letter:"V",
    ci: "",
    card: "",
    phoneP: "",
    email:"",
    name:"",
    account:"",
  })

  const {letter, ci, card, phoneP, email, name, account} = dataAuth;

  const clientContext=useContext(ClientContext);
  const {styleBrowserDefault, method, info, direction, setLoading, nextStep, setDirection}=clientContext;

  const { sendToAuth }=useContext(debitContext);

  
  //Modifica estilos
  useEffect(() => {
    M.AutoInit();
    window.scrollTo(0,0);
  }, []);

  //Pedir habilitar la ubicación para obtener latitud y longitud, sino error
  useEffect(() =>{
    if(direction.latitude ===""){
      let mounted = true;
      if(navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position => {
          if(mounted){
            setDirection(prevState => ({
              ...prevState,
              latitude:position.coords.latitude,
              longitude:position.coords.longitude
          }));
          }
        }), (error)=>{
          setDirection(prevState => ({
            ...prevState,
            latitude:"1",
            longitude:"1"
          }))
          // if(error.code === error.PERMISSION_DENIED){
          //   showError('6');
          //   handleError(error);
          //   timer(7000,"Recibo");
          // }
        });
      } else {
        // showError('7');
        // handleError(new Error('7'));
        // timer(7000,"Recibo");
        setDirection(prevState => ({
          ...prevState,
          latitude:"1",
          longitude:"1"
        }))
      }

      return () => mounted = false;
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Validación del form
  const { register, handleSubmit, trigger, formState: { errors } } = useForm({
    mode:"onBlur",
    reValidateMode:"onBlur"
  });

  /**
   * Método para actualizar el state del formulario dinámicamente
   * @function handleChange
   * @param {Event} e Evento que cambia un input en el form
   * @returns {void}
   */
   const handleChange=(e, errors)=>{
    if(errors){
        trigger(e.target.name);
    }
    if(e.target.name==="phoneP" && e.target.value==="+58 ("){
      e.target.value=""
    }
    setDataauth({
      ...dataAuth,
      [e.target.name] : e.target.value
    })
  }

  /**
   * Recopila los datos necesarios, los transforma y va a realizar el pago
   * @function onSubmit
   * @param {object} interdata la data del form 
   * @returns {void}
   */
  const onSubmit = interdata =>{
    var parser = new UAParser();
    var res = parser.getResult();
    const ciToSend = method.btn_short==="credicard" ? pad(interdata.ci, 9) : interdata.ci;
    setLoading(true);
    const auth = {
      osv: res.os.version,
      manu: res.device.vendor === undefined ? "N/A" : res.device.vendor,
      model: res.device.model === undefined ? "N/A" : res.device.model,
      bwa: res.browser.name,
      lat: direction.latitude.toString(),
      long: direction.longitude.toString(),
      cu: "VES",
      ci: `${interdata.letter}${ciToSend}`,
      order: info[0].orderId,
      m: info[0].amount_bs,
      ...(options.crdn && {crdn: removeNonDigits(interdata.card)}),
      ...(options.email && {email: interdata.email}),
      ...(options.phoneP && {phoneP: `${method.btn_short==="bvc"?"0":""}${removeNonDigits(interdata.phoneP).slice(2)}`}),
      ...(options.bank && {bank: method.btn_bank}),
      ...(options.account && {accountn: interdata.account}),
      ...(options.name && {naccount: interdata.name})
    }
    nextStep(4);
    sendToAuth(auth, method.btn_short);
  }

  return (
    <>
    {direction.latitude ==="" && <Spinner/>}
    {
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="margin-top-08 col s12 l12 titles-3 center-align">
          <h5 >Vas a pagar con <span>tarjeta de débito</span> un monto en Bs. de:</h5>
        </div>
        <div className="row">

          <div className="input-field col s12">
            <InputDisplayForCurrency
              amount={info[0].amount_bs}
              insideForm
            />
          </div>

          {options.bank && 
            (<div className="input-field col s12">
              <label className='active label-payments' htmlFor="selectedCCBank">Banco seleccionado</label>
              <Input
                disabled
                name="selectedCCBank"
                id="selectedCCBank"
                value={method.btn_bank_name}
                style={{textAlign:"center"}}
              />
            </div>)
          }

          {options.crdn &&
            (<div className="input-field col s12">
              <label className='active label-payments' htmlFor="card">Número de tarjeta</label>
              <Input
                name="card" 
                id="card"
                mask={"999999-9999-99999999"}
                register={register("card", {
                  required:{value:true,message:'Número de tarjeta es obligatorio'},
                  minLength:{value: 14, message:'Número de tarjeta debe tener mínimo 14 dígitos'},
                  validate: value => validateCard(value) || 'Número de tarjeta no válido',
                  onChange:(e) => handleChange(e, errors.card)
                })}
                placeholder="Ingresa tu número de tarjeta"
                className={errors.card ? "red-border": "input-form"}
              />
              <span className="helper-text invalid" data-error="wrong" >
                {errors.card && errors.card.message}
              </span>
            </div>)
          }

          <div className="col s12 label-dni">
            <label className='active label-payments' htmlFor="letter">Datos de tu documento de identidad</label>
          </div>
          <div className={`input-field col s8 offset-s2 m3 offset-m0 center-align no-margin-bottom`}>
            <select
              name="letter"
              id="letter"
              {...register("letter", {
                required:{value:true,message:'La nacionalidad es obligatoria'},
                onChange: (e) => handleChange(e, errors.letter)
              })}
              className={`input-form ${styleBrowserDefault && 'browser-default'}`}
            >
              <option value="V">V</option>
              <option value="E">E</option>
              <option value="J">J</option>
              <option value="G">G</option>
              <option value="C">C</option>
            </select>
          </div>

          <div className="input-field col s12 m9">
              <Input
                name="ci"
                id="ci"
                mask="999999999" 
                register={register("ci", {
                  required:{value:true,message:'Este campo es obligatorio'},
                  validate: value => validateRif(value, letter) || "El RIF no es válido",
                  ...(letter==="J" ?
                    {minLength:{value:9,message:"Este campo debe tener 9 caracteres"}} : 
                    {minLength:{value:6,message:"Este campo debe tener al menos 6 caracteres"}}
                  ),
                  onChange: (e) => handleChange(e, errors.ci)
                })}
                placeholder="Cédula/RIF"
                className={errors.ci ? "red-border": "input-form"}
              />
              <span className="helper-text invalid" data-error="wrong" >
                {errors.ci && errors.ci.message}
              </span>
          </div>

          {options.name &&
            (
              <div className="input-field col s12">
                <label className='active label-payments' htmlFor="name">Nombre y apellido</label>
                <Input
                  name="name"
                  id="name"
                  maxLength="40"
                  register={register("name", {
                    required:{value:true,message:'Nombre del tarjeta habiente es requerido'},
                    maxLength:{value:40,message:"este campo debe tener máximo 40 caracteres"},
                    validate: value => noNumbers(value) || 'Nombre del tarjeta habiente no válido',
                    onChange: (e) => handleChange(e, errors.name)
                  })}
                  placeholder="Ingresa tu nombre y apellido"
                  className={errors.name ? "red-border": "input-form"}
                />
                <span className="helper-text invalid" data-error="wrong" >
                  {errors.name && errors.name.message}
                </span>
              </div>
            )
          }

          {options.account &&
            (
              <div className="input-field col s12">
                <label className='active label-payments' htmlFor="account">Número de cuenta</label>
                <Input
                  name="account" 
                  id="account"
                  mask="99999999999999999999"
                  register={register("account", {
                    minLength:{value:20,message:"El número de cuenta debe tener 20 dígitos"},
                    maxLength:{value:20,message:"El número de cuenta debe tener 20 dígitos"},
                    required:{value:true,message:'Número de cuenta es obligatorio'},
                    validate: value => isBolivarAccount(value) || 'El número de cuenta no es válido',
                    onChange: (e) => handleChange(e, errors.account)
                  })}
                  placeholder="Ingresa tu número de cuenta"
                  className={errors.account ? "red-border": "input-form"}
                />
                <span className="helper-text invalid" data-error="wrong" >
                  {errors.account && errors.account.message}
                </span>
              </div>
            )
          }

          {method.btn_canal === "0104" && options.email &&
            <Email
              message={false}
              register={register("email", {
                required:{value:true,message:'El correo es requerido'},
                validate: value => emailValidator.test(value) || 'El correo no es válido',
                onChange: (e) => handleChange(e, errors.email)
              })}
              errors={errors.email}
            />
          }

          {options.phoneP && <PhoneP
            message={method.btn_canal==="0104"}
            register={register("phoneP", {
              required:{value:true,message:'El número telefónico del cliente es obligatorio'},
              minLength:{value:19,message:"Coloque un número telefónico válido"},
              onChange: (e) => handleChange(e, errors.phoneP)
            })}
            errors={errors.phoneP}
          />}

          {method.btn_canal !== "0104" && options.email &&
            <Email
              message={true}
              register={register("email", {
                required:{value:true,message:'El correo es requerido'},
                validate: value => emailValidator.test(value) || 'El correo no es válido',
                onChange: (e) => handleChange(e, errors.email)
              })}
              errors={errors.email}
            />
          }

          <div className="input-field col s12">
            <button
              className="buttonPay"
              disabled={
                (options.crdn && card==="") ||
                (options.phoneP && phoneP==="") ||
                (options.email && email==="") ||
                (options.name && name==="") ||
                (options.account && account==="") ||
                letter==="" ||
                ci===""
              }
            >
              Continuar
            </button>
          </div>
        </div>
      </form>
    }
    </>
  );
};

export default TddAuth;
