//DEBIT
export const PAGAR_DEBITO='PAGAR_DEBITO';
export const SET_DATA_AUTH='SET_DATA_AUTH';
export const DEBIT_AUTH='DEBIT_AUTH';
export const SET_OPTIONS='SET_OPTIONS';
//CLIENT
export const SET_DATA_CLIENT='SET_DATA_CLIENT';
export const SET_LOADING='SET_LOADING';
export const CHANGE_METHOD='CHANGE_METHOD';
export const CHANGE_COIN='CHANGE_COIN';
export const FORM_PAYMENT='FORM_PAYMENT';
export const PAGO_DATA='PAGO_DATA';
export const ERROR_DATA = 'ERROR_DATA';
export const SET_METHODS = 'SET_METHODS';
export const CHANGE_METHOD_DEBIT= 'CHANGE_METHOD_DEBIT';
export const SET_APP = 'SET_APP';
export const SET_TOTAL = 'SET_TOTAL';
export const SET_COUNTRIES = 'SET_COUNTRIES';
//P2C
export const PAGO_P2C = 'PAGO_P2C';
//C2P
export const PAGO_C2P = 'PAGO_C2P';
//INTERNACIONAL
export const PAGO_INTERNACIONAL='PAGO_INTERNACIONAL';
export const CHARGE_AMOUNT = 'CHARGE_AMOUNT';
//UBIIAPP
export const PAGO_UBII = 'PAGO_UBII';
//ZELLE
export const PAGO_ZELLE = 'PAGO_ZELLE';
export const TOKEN_ZELLE = 'TOKEN_ZELLE';
//CASH
export const PAGO_CASH = 'PAGO_CASH';
//ALERT
export const SET_ALERT = 'SET_ALERT';
