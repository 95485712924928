import { PAGO_CASH } from '../../types';

export default (state, action) => {

    const {type, payload} = action;

    switch (type) {
        case PAGO_CASH:
            return {
                ...state,
                dataCash: payload,
                processCash:true
            }
        default:
            return state;
    }

}