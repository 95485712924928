import React from 'react';

//Cuando es teléfono aparece el carrusel, de resto aparece la lista
const MethodCard = ({methodArray, currentMethod, setMethod}) => {
    return (
        <>
            <div className="carousel" data-testid="carousel">
                {
                    methodArray.map((m, i) => (
                        <div
                            className={`carousel-item ${currentMethod === m.btn_key ? 'active-border' : ''}`} 
                            key={m.btn_key} 
                            index={i}
                            onClick={setMethod}
                            value={m.btn_key}
                        >
                            <div 
                                style={{width:"100%"}}
                            >
                                <div className="method-card" style={{backgroundImage: `url(${m.btn_img})`}}></div>
                                <div>{m.btn_name}</div>
                            </div>
                        </div>
                    ))
                }
            </div>
            <div className="listed-items-wrapper row" style={{margin:"auto"}} data-testid="listed-items">
                {
                    methodArray.map((m, i) => (
                        <div 
                            className="col s6 card-wrapper"
                            style={{padding: i%2===0 ? "0 .48rem 0 0" : "0 0 0 .48rem"}}
                            key={m.btn_key}
                            index={i}
                            value={m.btn_key} 
                            onClick={setMethod}
                        >
                            <div 
                                className={`listed-item col-content ${currentMethod === m.btn_key ? 'active-border' : ''}`}
                                id={m.btn_alias}
                            >
                                <div 
                                    style={{width:"100%"}}
                                >
                                    <div className="method-card" style={{backgroundImage: `url(${m.btn_img})`}}></div>
                                    <span>{m.btn_name}</span>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </>
     );
}
 
export default MethodCard;