import React, { useState, useContext, useEffect } from 'react';
import { useForm } from "react-hook-form";
import cashContext from '../../context/Cash/cashContext';
import Spinner from '../spinner/Spinner';
import ClientContext from '../../context/Client/clientContext';
import M from "materialize-css";
import { InputDisplayForCurrency } from '../partials/CurrencyMask';
import parse from 'html-react-parser'

const CashPay = () => {

    //States
    const efectivoContext = useContext(cashContext);
    const { sendCash } = efectivoContext;

    const clientContext = useContext(ClientContext);
    const { method, loading, info, setLoading } = clientContext;

    const [disabledTimer, setdisabledTimer] = useState(true)


    //Validación del form
    const { handleSubmit } = useForm({
        mode:"onBlur",
        reValidateMode:"onBlur"
    });

    /**
     * Recopila los datos necesarios, los transforma y va a realizar el pago
     * @function onSubmit
     * @param {object} interdata la data del form 
     * @returns {void}
     */
    const onSubmit = interdata =>{

        setLoading(true)

        const message = {
            m: info[0].amount_ds,
            cu: 'USD',
            order: info[0].orderId,
        }

        sendCash(message);
    }

    //Modifica estilos
    useEffect( ()=>{
        M.AutoInit();
        window.scrollTo(0,0);

        let enableButtonTimer = setTimeout(()=> {setdisabledTimer(false)}, 5000);
        return () => clearTimeout(enableButtonTimer);
    }, [] );

    return ( 
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="margin-top-08 col s12 l12 titles-3 center-align">
                    <h5 >Vas a pagar con <span>Efectivo</span> un monto en dólares de:</h5>
                </div>
                {loading && <Spinner/>}
                <div className="row">

                    <div className="input-field col s12">
                        <InputDisplayForCurrency 
                            amount={info[0].amount_ds}
                            insideForm
                        />
                    </div>

                    <div className="col s12 l12 titles-3 center-align">
                        <h5> 
                            <b>
                                <span>Información del pago: </span>
                            </b>
                        </h5>
                    </div>

                    <div className="cash-instructions-card col s12 l12 center-align">
                        <h5>
                            <span>{parse(method.cash_info)}</span>
                        </h5>
                    </div>

                    <div className="input-field col s12">
                        <button
                            className="buttonPay loading-button"
                            disabled={disabledTimer && "cash_info" in method}
                        >
                            ¡Listo!
                        </button>
                        <div className="fadingEffect"></div>
                    </div>
                </div>
            </form>
        </>
     );
}
 
export default CashPay;