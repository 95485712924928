import React,{useContext, useReducer} from 'react';
import payContext from './payContext';
import payReducer from './payReducer';
import {PAGO_DATA, SET_DATA_CLIENT, CHARGE_AMOUNT} from '../../types'
import ClientContext from '../Client/clientContext'
import errorContext from '../Error/errorContext';

const PayState= props=>{

    //States
    const initialState={
        processPayment:false,
        pagoData:'',
        data_client_tdc:null,
        processChargeMicroCTDC: false
    }

    const clientContext=useContext(ClientContext);
    const {setLoading, method, handleError, pay, nextStep}=clientContext;
    const { showError } = useContext(errorContext);

    //Dispatch para ejecutar las acciones
    const [state, dispatch] = useReducer(payReducer, initialState)

    /**
     * Envía datos al endpoint final de tarjeta de crédito. Coloca en el contexto el resultado de la transacción, o el error si hubo alguno 
     * @async
     * @function sendPayment
     * @param {object} datos información enviada desde el formulario de tdc
     * @returns {void}
     */
    const sendPayment= async datos=>{
        try {
            dispatch({
                type:SET_DATA_CLIENT,
                payload:datos  
            })

            const response = await pay('payment_tdc', datos, method.btn_key);
            //Primera vez que debe poner el monto o tarjeta en proceso de activación va a poner monto
            if((response.data.R === "0" && response.data.codR==="80") || (response.data.R === "1" && response.data.codR==="999")){
                dispatch({
                    type: CHARGE_AMOUNT,
                    payload: response
                });
                nextStep(3);
            //Tarjeta bloqueada, transacción aprobada o transacción negada para factura
            }else{
                dispatch({
                    type: PAGO_DATA,
                    payload: response
                });
            }

            setLoading(false)
            
        } catch (error) {
            console.log(error);
            showError('1');
            handleError(error);
            setLoading(false)
        }
    }

    /**
   * Setea la variable processPayment del contexto y los datos de la transacción
   * @function setProcessPaymentTdc
   * @param {object} resp información enviada desde tdcMicroC
   * @returns {void}
   */
    const setProcessPaymentTdc = (resp) =>{
        dispatch({
            type: PAGO_DATA,
            payload: resp
        });
    }
    
    return (
        <payContext.Provider
            value={{
                processPayment:state.processPayment,
                pagoData:state.pagoData,
                data_client_tdc: state.data_client_tdc,
                processChargeMicroCTDC: state.processChargeMicroCTDC,
                sendPayment,
                setProcessPaymentTdc
            }}
        >
            {props.children}
        </payContext.Provider>
    )
}
export default PayState;