import {PAGAR_DEBITO, SET_DATA_CLIENT, DEBIT_AUTH, SET_DATA_AUTH, SET_OPTIONS} from '../../types'
    
export default (state,action) =>{

    const {type, payload} = action;

    switch (type) {
        case DEBIT_AUTH:
            return{
                ...state,
                processAuth: payload.data.R === "0",
                response:payload
            }

        case PAGAR_DEBITO:
            return{
                ...state,
                response: null,
                processPaymentDebit:true,
                loading:false,
                dataDebit:payload
            }
        
        case SET_DATA_AUTH:
            return{
                ...state,
                dataAuth: payload
            }
        
        case SET_DATA_CLIENT:
            return {
                ...state,
                data_client_tdd:payload
            }
        
        case SET_OPTIONS:
            return {
                ...state,
                options: payload
            }

        default:
            return state;
    }
}