/**
 * Busca el tipo de navegador y devuelve si es Instagram o no
 * @function isInstagram
 * @returns {boolean} true si es el navegador de instagram (y si se está usando la librería npm)
 */
 export function isInstagram() {
    var ua = navigator.userAgent || navigator.vendor || window.opera;
    var isInstagram = ua.indexOf('Instagram') > -1;
    return (window.opener && isInstagram === true)
}

/**
 * Busca el tipo de navegador y devuelve si el dispositivo es Ios
 * @function isIos
 * @returns {boolean} true si es el navegador ios (y si se está usando la librería npm)
 */
export function isIos() {
    var ua = navigator.userAgent || navigator.vendor || window.opera;
    var isIos = /iPad|iPhone|iPod/.test(ua);
    return (window.opener && isIos === true)
}

/**
 * Función que revisa si la página se abrió en un iframe
 * @function isIframe
 * @returns {boolean} true si está en un iframe (y si se está usando la librería npm)
 */
export function isIframe() {
    return (window.opener && window !== window.parent);
}