import React,{ useEffect } from 'react';
import Form from './components/Form';
import 'materialize-css/dist/css/materialize.min.css';
import PayState from './context/Pay/payState';
import DebitState from './context/payDebit/debitState';
import InternacionalState from './context/Internacional/internacionalState';
import P2cState from './context/P2c/p2cState';
import UbiiAppState from './context/ubiiApp/ubiiAppState';
import ClientState from './context/Client/clientState';
import ErrorState from './context/Error/errorState';
import ZelleState from './context/zelle/zelleState';
import CashState from './context/Cash/cashState';
import C2pState from './context/C2p/c2pState';
import { isInstagram } from './helpers/browser-check';

function App() {

  useEffect(() => {

    const root = document.getElementById('root');
    if(root){
      root.classList.add('set-background');
    }
  }, []);
  
  return (
      <ErrorState>
        <ClientState>
          <PayState>
            <DebitState>
              <InternacionalState>
                <P2cState>
                  <UbiiAppState>
                    <ZelleState>
                      <CashState>
                        <C2pState>
                          <div className={`container${isInstagram() ? ' site-container-instagram' : ''}`}>          
                            <Form/>
                            <div className="version">{`V. ${process.env.REACT_APP_VERSION}`}</div>
                          </div>
                        </C2pState>
                      </CashState>
                    </ZelleState>
                  </UbiiAppState>
                </P2cState>
              </InternacionalState>
            </DebitState>
          </PayState>
        </ClientState>
      </ErrorState>
  );
}

export default App;
