import React, { useState,useEffect, useContext } from "react";
import M from "materialize-css";
import P2cContext from '../../context/P2c/p2cContext';
import ClientContext from '../../context/Client/clientContext';
import { useForm, Controller } from "react-hook-form";
import Spinner from "../spinner/Spinner";
import Input from '../partials/Input';
import {removeNonDigits, dateP2c} from '../../helpers/str';
import { InputDisplayForCurrency } from "../partials/CurrencyMask";
import Select from "react-select";
import { customStyles } from "../internacional/TdcInternacional";

const P2c = () => {

  //States
  const [pmovil, guardarP2c] = useState({
    bank:'',
    phoneP:'',
    // ref:'',
    // letter:'V',
    // dni:''
  });

  const {bank, phoneP,
    // ref, dni, letter
  } = pmovil;

  const finalDate = dateP2c(new Date(Date.now()));

  const p2cContext = useContext(P2cContext);
  const {sendP2c} = p2cContext;

  const clientContext=useContext(ClientContext);
  const {clientP2c, loading,setLoading,method,info}=clientContext;

  //Validación del form
  const { register, handleSubmit, trigger, control, formState: { errors } } = useForm({
    mode:"onBlur",
    reValidateMode:"onBlur"
  });

  /**
   * Método para actualizar el state del formulario dinámicamente
   * @function handleChange
   * @param {Event} e Evento que cambia un input en el form
   * @returns {void}
   */
   const handleChange=(e, errors)=>{
    if(errors){
      trigger(e.target.name);
    }
    if(e.bank_code){
      guardarP2c({
          ...pmovil,
          bank: e.bank_code
      });
    }else {
      if(e.target.name==="phoneP" && e.target.value==="+58 ("){
        e.target.value=""
      }
      guardarP2c({
        ...pmovil, 
        [e.target.name]:e.target.value
      })
    }
  }
  
  //Modifica estilos
  useEffect(() => {
    M.AutoInit();
    window.scrollTo(0,0);
  }, []);

    /**
     * Recopila los datos necesarios, los transforma y va a realizar el pago
     * @function onSubmit
     * @param {object} interdata la data del form 
     * @returns {void}
     */
    const onSubmit =  interdata => {
      
      setLoading(true)

      const message = {
        date: finalDate,
        // ref: interdata.ref,
        bank:interdata.bank,
        // ci:'',
        // phoneC:'',
        m:info[0].amount_bs,
        phoneP:`00${removeNonDigits(interdata.phoneP)}`,
        order: info[0].orderId,
        // ...(method.btn_canal==="0104" && {rif_client:interdata.letter+""+interdata.dni})
      }
      sendP2c(message)
    }

    return (
      <form 
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="margin-top-08 col s12 l12 titles-2 center-align">
          <h5 >Información del pago</h5>
        </div>
        {loading && <Spinner />}
        <div className="row">

          <div className="input-field col s12 center-align">
            <div className="center-align">
              <img src="/images/icons/checker.svg" alt="chequera"/>
            </div>
            <label className='active label-payments' htmlFor="totalAmount">Verifica y toma nota del monto a pagar en bolívares.</label>
              <InputDisplayForCurrency 
                amount={info[0].amount_bs}
                insideForm
              />
          </div>

          <div className="input-field col s12 center-align">
            <div className="center-align">
              <img src="/images/icons/phone-pay.svg" alt="correo"/>
            </div>
            <label className='active label-payments' htmlFor="email">Realiza el pago móvil con los datos presentados a continuación.</label>
            <div className="ubiiCard">
              <p className="b">Nombre</p>
              <p className="mb">{clientP2c.nombre}</p>
              <p className="b">RIF</p>
              <p className="mb">{clientP2c.rif}</p>
              <p className="b">Banco</p>
              <p className="mb">{clientP2c.banco}</p>
              <p className="b">Número Telefónico</p>
              <p className="mb">{clientP2c.telf}</p>
            </div>
          </div>

          {/* <div className="input-field col s12 center-align">
            <div className="center-align">
              <img src="/images/icons/ref.svg" alt="referencia"/>
            </div>
            <label className='active label-payments' htmlFor="ref">Registra los últimos 6 dígitos de la referencia del pago</label>
            <Input
              name="ref"
              id="ref"
              mask="999999999999"
              register={register("ref", {
                required:{value:true,message:'El numero de referencia de la transacción es obligatorio'},
                maxLength: {value: 12, message: 'El número de referencia no puede exceder los 12 caracteres'},
                minLength: {value: 5, message: 'El número de referencia debe tener al menos 5 caracteres'},
                onChange: (e) => handleChange(e, errors.ref)
              })}
              placeholder="Ingresa la referencia"
              className={errors.ref ? "red-border": "input-form"}
              autoComplete="off"
            />
            <span className="helper-text invalid" data-error="wrong" >
              {errors.ref && errors.ref.message}
            </span>
          </div> */}

          <div className={`input-field col s12 center-align ${errors.bank ? "border-input-dropdown-error" : ""}`}>
            <div className="center-align">
              <img src="/images/icons/bank.svg" alt="banco"/>
            </div>
            <label className='active label-payments' htmlFor="bank">Selecciona el banco que usaste para el pago</label>
                <Controller
                  id="bank-drop"
                  control={control}
                  name="bank"
                  rules={{required:{value:true,message:'El banco es requerido'}}}
                  render={({ field: { onChange } }) => (
                    <Select
                      options={method.banks_p2c}
                      getOptionLabel ={(option)=>option.bank_name}
                      getOptionValue ={(option)=>option.bank_code}
                      components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                      styles={customStyles}
                      classNamePrefix="react-select"
                      noOptionsMessage={() => 'Banco no encontrado'}
                      placeholder="Selecciona el banco"
                      onChange={(e) => {
                        onChange(e.bank_code);
                        e.target={name: "bank"};
                        handleChange(e, errors.bank);
                      }}
                      autoComplete="off"
                    />
                  )}
                />
              <span className="helper-text invalid" data-error="wrong" >
                  {errors.bank && errors.bank.message}
              </span>
          </div>

          {/* { method.btn_canal==="0104" && 
            <Fragment>
              <div className="col s12 center-align">
                <div className="center-align">
                  <img src="/images/icons/identification.svg" alt="documento de identidad"/>
                </div>
                <label className='active label-payments' htmlFor="letter">Datos de tu documento de identidad</label>
              </div>
              <div className={`input-field col s8 offset-s2 m3 offset-m0 center-align no-margin-bottom`}>
                <select
                  name="letter"
                  id="letter"
                  {...register("letter", {
                    required:{value:true,message:'La nacionalidad es obligatoria'},
                    onChange: (e) => handleChange(e, errors.letter)

                  })}
                  className="input-form"
                  autoComplete="off"
                >
                  <option value="V">V</option>
                  <option value="E">E</option>
                  <option value="J">J</option>
                  <option value="G">G</option>
                  <option value="C">C</option>
                </select>
              </div>

              <div className="input-field col s12 m9">
                <Input
                  name="dni"
                  id="dni"
                  mask="999999999" 
                  register={register("dni", {
                    required:{value:true,message:'Este campo es obligatorio'},
                    validate: value => validateRif(value, letter) || "El RIF no es válido",
                    ...(letter==="J" ?
                        {minLength:{value:9,message:"Este campo debe tener 9 caracteres"}} : 
                        {minLength:{value:6,message:"Este campo debe tener al menos 6 caracteres"}}
                    ),
                    onChange: (e) => handleChange(e, errors.dni)
                  })}
                  placeholder="Cédula/RIF"
                  className={errors.dni ? "red-border": "input-form"}
                />
                <span className="helper-text invalid" data-error="wrong" >
                  {errors.dni && errors.dni.message}
                </span>
              </div>
            </Fragment>
          } */}

          <div className="input-field col s12 center-align">
            <div className="center-align">
              <img src="/images/icons/dial.svg" alt="numero telefónico"/>
            </div>
            <label className='active label-payments' htmlFor="phoneP">Ingresa tu número de teléfono</label>
            <Input
              name="phoneP" 
              id="phoneP"
              mask={phoneP!=="" ? "+58 (999) 999 99 99":""}
              register={register("phoneP", {
                required:{value:true,message:'El número telefónico del cliente es obligatorio'},
                minLength:{value:19,message:"Coloque un número telefónico válido"},
                onChange: (e) => handleChange(e, errors.phoneP)
              })}
              placeholder="+58 ("
              className={errors.phoneP ? "red-border": "input-form"}
              inputMode="numeric"
              // autoComplete="off"
            />
            <span className="helper-text invalid" data-error="wrong" >
              {errors.phoneP && errors.phoneP.message}
            </span>
          </div> 

          <div className="input-field col s12">
            <button
              className="buttonPay"
              disabled={phoneP==="" || bank===""}
            >
              ¡Listo!
            </button>
          </div>
        </div> 
      </form>
    );
};

export default P2c;