import React, { useState, useContext, useEffect } from 'react';
import debitContext from '../../context/payDebit/debitContext';
import { useForm } from 'react-hook-form';
import Spinner from '../spinner/Spinner';
import ClientContext from '../../context/Client/clientContext';
import Input from '../partials/Input';
import M from "materialize-css";
import { removeNonDigits, noNumbers, validateCard, uppercase } from "../../helpers/str";
import Tooltip from '../partials/Tooltip';

const TddCredicard = () => {

    //States
    const [dataForm, setData] = useState({
        ncrd:'',
        atype:'',
        tf:'',
        cvv:'',
        month:'',
        year:'',
        crdn: '',
        phoneP:'',
        pin:''
    })

    const {ncrd, atype, tf, cvv, month, year, crdn, phoneP, pin} = dataForm;

    const clientContext=useContext(ClientContext);
    const {styleBrowserDefault, method,months,setLoading,loading,info, years}=clientContext;
    
    const {response, dataAuth, sendPayment} = useContext(debitContext);

    //Validación del form
    const { handleSubmit, register, trigger, formState: { errors } } = useForm({
        mode:"onBlur",
        reValidateMode:"onBlur"
    })

    //Modifica estilos
    useEffect(() =>{
        M.AutoInit();
        window.scrollTo(0,0);
    },[]);

    /**
     * Recopila los datos necesarios, los transforma y va a realizar el pago
     * @function onSubmit
     * @param {object} interdata la data del form 
     * @returns {void}
     */
    const onSubmit = interdata =>{

        setLoading(true)
        const message = {
            m: info[0].amount_bs,
            country: "VE",
            cu: "VES",
            crdn: removeNonDigits(interdata.crdn),
            fexp: `${interdata.month}/${interdata.year.slice(2)}`,
            ncrd: interdata.ncrd,
            typeDoc: "CI",
            ci: dataAuth.ci,
            ta: interdata.atype,
            pin:interdata.pin,
            cvv:interdata.cvv,
            phoneP:`0412${removeNonDigits(interdata.phoneP).slice(5)}`,
            tokenP: interdata.tf,
            token: response.data.token,
            order: info[0].orderId,
            lat: dataAuth.lat,
            long: dataAuth.long
        }
        sendPayment(message, method.btn_short);
    }
    
  /**
   * Método para actualizar el state del formulario dinámicamente
   * @function handleChange
   * @param {Event} e Evento que cambia un input en el form
   * @returns {void}
   */
    const handleChange=(e, errors)=>{
        if(errors){
            trigger(e.target.name);
        }
        if(e.target.name==="ncrd"){
            e.target.value=uppercase(e.target.value)
        }
        if(e.target.name==="phoneP" && e.target.value==="+58 ("){
            e.target.value=""
        }
        setData({
            ...dataForm,
            [e.target.name] : e.target.value
        })
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {loading && <Spinner/>}

            <h5 className="label-payments">Tus datos</h5>
            <div className="ubiiCard size-tdd-info">
                <p className="b">{dataAuth.ci}</p>
                <p className="b">{method.btn_bank_name}</p>
            </div>

            <div className="row">

                <div className="input-field col s12">
                    <label className='active label-payments' htmlFor="ncrd">Nombre del titular de la tarjeta</label>
                    <Input
                        name="ncrd"
                        id="ncrd"
                        onChange={(e) => handleChange(e, errors.ncrd)}
                        maxLength="40"
                        register={register("ncrd", {
                            required:{value:true,message:'Nombre del tarjeta habiente es requerido'},
                            maxLength:{value:40,message:"este campo debe tener máximo 40 caracteres"},
                            validate: value => noNumbers(value) || 'Nombre del tarjeta habiente no válido',
                            onChange: (e) => handleChange(e, errors.ncrd)
                        })}
                        placeholder="Nombre del titular que aparece en la tarjeta"
                        className={errors.ncrd ? "red-border": "input-form"}
                    />
                    <span className="helper-text invalid" data-error="wrong" >
                        {errors.ncrd && errors.ncrd.message}
                    </span>
                </div>

                <div className="input-field col s12">
                    <label className='active label-payments' htmlFor="crdn">Número de tarjeta</label>
                    <Input
                        name="crdn" 
                        id="crdn"
                        mask="999999-99999-99999999"
                        register={register("crdn", {
                            required:{value:true,message:'Número de tarjeta es obligatorio'},
                            minLength:{value: 14, message:'Número de tarjeta debe tener mínimo 14 dígitos'},
                            validate: value => validateCard(value) || 'Número de tarjeta no válido',
                            onChange: (e) => handleChange(e, errors.crdn)
                        })}
                        onChange={(e) => handleChange(e, errors.crdn)}
                        placeholder="Ingresa tu número de tarjeta"
                        className={errors.crdn ? "red-border": "input-form"}
                    />
                    <span className="helper-text invalid" data-error="wrong" >
                        {errors.crdn && errors.crdn.message}
                    </span>
                </div>

                <div className="input-field col s12">
                    <div className="row fix-to-bottom no-margin-bottom">
                        <div className={`input-field col s12 m4 ${errors.month ? "border-input-dropdown-error" : ""}`}>
                            <label className='active label-payments' htmlFor="month">Mes de vencimiento</label>
                            <select
                                name="month"
                                id="month"
                                {...register("month", {
                                    required:{value:true,message:'El mes es obligatorio'},
                                    onChange: (e) => handleChange(e, errors.month)
                                })}
                                className={`input-form ${styleBrowserDefault && 'browser-default'}`}
                                // autoComplete="off"
                            >
                                <option value="">Mes -</option>
                                {months && months.map(month=>(
                                    <option value={month.value} key={month.value}>{month.description}</option>
                                ))}
                            </select>
                            <span className="helper-text invalid" data-error="wrong" >
                                {errors.month && errors.month.message}
                            </span>
                        </div>

                        <div className={`input-field col s12 m4 ${errors.year ? "border-input-dropdown-error" : ""}`}>
                            <label className='active label-payments' htmlFor="year">Año de vencimiento</label>
                            <select
                                name="year"
                                id="year"
                                {...register("year", {
                                    required:{value:true,message:'El año es obligatorio'},
                                    onChange: (e) => handleChange(e, errors.year)
                                })}
                                className={`input-form ${styleBrowserDefault && 'browser-default'}`}
                            >
                                <option value="">Año -</option>
                                {years}
                            </select>
                            <span className="helper-text invalid" data-error="wrong" >
                                {errors.year && errors.year.message}
                            </span>
                        </div>

                        <div className="input-field col s12 m4">
                            <label className='active label-payments' htmlFor="cvv">CVV</label>
                            <Tooltip 
                                message="Son los tres dígitos que se ubican en la parte inversa de tu tarjeta" 
                                icon="info_outline"
                                iconClass="info-icon"
                            />
                            <Input
                                name="cvv"
                                id="cvv"
                                type="password"
                                mask="9999"
                                register={register("cvv", {
                                    required:{value:true,message:'El cvv es obligatorio'},
                                    maxLength:{value:4,message:"El cvv debe tener máximo 4 dígitos"},
                                    minLength:{value:3,message:"El cvv debe tener minimo 3 dígitos"},
                                    onChange: (e) => handleChange(e, errors.cvv)
                                })}
                                className={errors.cvv ? "red-border": "input-form"}
                            />
                            <span className="helper-text invalid" data-error="wrong" >
                                {errors.cvv && errors.cvv.message}
                            </span>
                        </div>
                    </div>
                </div>

                <div className={`input-field col s12 ${errors.atype ? "border-input-dropdown-error" : ""}`}>
                    <label className='active label-payments' htmlFor="atype">Selecciona la cuenta</label>
                    <select
                        name="atype"
                        id="atype"
                        {...register("atype", {
                            required:{value:true,message:'Tipo de cuenta obligatorio'},
                            onChange: (e) => handleChange(e, errors.atype)
                        })}
                        className={`input-form ${styleBrowserDefault && 'browser-default'}`}
                    >
                        <option value="">Cuenta</option>
                        <option value="CORRIENTE">Cuenta Corriente</option>
                        <option value="AHORROS">Cuenta de Ahorros</option> 
                    </select>
                    <span className="helper-text invalid" data-error="wrong" >
                        {errors.atype && errors.atype.message}
                    </span>
                </div>

                <div className="input-field col s12">
                    <label className='active label-payments' htmlFor="tf">Token</label>
                    <Input
                        name="tf"
                        id="tf"
                        mask="********"
                        register={register("tf", {
                            required:{value:true,message:'El token es obligatorio'},
                            maxLength: {value: 8, message: 'El token debe tener máximo 8 caracteres'},
                            minLength:{value: 4, message: 'El token debe tener mínimo 4 caracteres'},
                            onChange: (e) => handleChange(e, errors.tf)
                        })}
                        className={errors.tf ? "red-border": "input-form"}
                        placeholder="Ingrese aquí el token que le llegó a su teléfono:"
                    />
                    <span className="helper-text invalid" data-error="wrong" >
                        {errors.tf && errors.tf.message}
                    </span>
                </div>

                <div className="input-field col s12">
                    <label className='active label-payments' htmlFor="pin">PIN de la tarjeta</label>
                    <Tooltip 
                        message="Es la clave que utiliza para hacer transacciones con su tarjeta" 
                        icon="info_outline"
                        iconClass="info-icon"
                    />
                    <Input
                        name="pin"
                        id="pin"
                        mask="999999"
                        register={register("pin", {
                            required:{value:true,message:'El pin es obligatorio'},
                            maxLength: {value: 6, message: 'El pin debe tener máximo 6 dígitos'},
                            minLength:{value: 4, message: 'El pin debe tener mínimo 4 dígitos'},
                            onChange: (e) => handleChange(e, errors.pin)
                        })}
                        className={errors.pin ? "red-border": "input-form"}
                        placeholder="Ingrese aquí el PIN"
                    />
                    <span className="helper-text invalid" data-error="wrong" >
                        {errors.pin && errors.pin.message}
                    </span>
                </div>

                <div className="input-field col s12">
                    <label className='active label-payments' htmlFor="phoneP">Número de teléfono</label>
                    <Input
                        name="phoneP" 
                        id="phoneP"
                        mask="+58 (999) 999 99 99"
                        register={register("phoneP", {
                            required:{value:true,message:'El número telefónico del cliente es obligatorio'},
                            minLength:{value:19,message:"Coloque un número telefónico válido"},
                            onChange: (e) => handleChange(e, errors.phoneP)
                        })}
                        placeholder="+58 ("
                        className={errors.phoneP ? "red-border": "input-form"}
                        inputMode="numeric"
                    />
                    <span className="helper-text invalid" data-error="wrong" >
                        {errors.phoneP && errors.phoneP.message}
                    </span>
                    <span style={{color:"#635bff"}}>Se enviará a tu teléfono un Código de validación para efectuar el pago.</span>
                </div>

                <div className="input-field col s12">
                    <button
                        className="buttonPay"
                        disabled={
                            atype==="" ||
                            ncrd==="" ||
                            crdn==="" ||
                            phoneP==="" ||
                            pin==="" ||
                            cvv==="" || 
                            tf==="" ||
                            year==="" ||
                            month===""
                        }
                    >
                        Pagar
                    </button>
                </div>
            </div>
        </form>
     );
}
 
export default TddCredicard;