import Swal from 'sweetalert2';

export let clock;
export let globalC;
export let clock2;

export function stopTimer(){
    clearTimeout(clock);
}

export function stopTimer2(){
    clearTimeout(clock2);
}

export function stopGlobalTimer(){
    clearTimeout(globalC);
}

/**
 * Pone en marcha el temporizador para cerrar la página con una alerta
 * @function timer
 * @param {number} num tiempo del timer en milisegundos
 * @param {string} trigger define qué está llamando al timer
 */
export function timer(num,trigger){
    if(window.opener){
        if(trigger==="App"){
            globalC= setTimeout(() => {
                alertTimeout();
            }, num);
        }else if(trigger==="Recibo"){
            clock= setTimeout(() => {
                closeFinalPage();
            }, num);
        }
    }
}

/**
 * Pone en marcha el temporizador para mostrar la página de tiempo finalizado (link de pago)
 * @function timer2
 * @param {number} num tiempo del timer en milisegundos
 */
 export function timer2(num, func){
    clock2 = setTimeout(() => {
        runOutOfTimeModal(func);
    }, num);
}

/**
 * Cierra la página
 */
export function reload(){
    window.close();
}

/**
 * Muestra la alerta que debe salir cuando pase el límite de sesión en la pasarela
 * @function alertTimeout
 */
export function alertTimeout(){
    Swal.fire({
        showConfirmButton:false,
        title: 'Límite de tiempo alcanzado',
        imageUrl: 'https://www.ubiipagos.com/img/logo.png',
        imageWidth:"200",
        html:'La página se cerrará ahora.',
        allowOutsideClick:false,
        allowEscapeKey:false
    });
    setTimeout(() => {
        reload();
    }, 6000); 
}

/**
 * Muestra la alerta que debe salir cuando se realiza el pago y se ha mostrado el recibo
 * @function closeFinalPage
 */
export function closeFinalPage(){
    Swal.fire({
        showConfirmButton:false,
        title: 'La página se cerrará ahora',
        imageUrl: 'https://www.ubiipagos.com/img/logo.png',
        imageWidth:"200",
        html:'Gracias por usar Ubii',
        allowOutsideClick:false,
        allowEscapeKey:false
    });
    setTimeout(() => {
        reload();
    }, 6000); 
}

/**
 * Muestra la alerta que debe salir cuando se acaba el tiempo en link de pago
 * @function runOutOfTimeModal
 */
 export function runOutOfTimeModal(func){
    Swal.fire({
        showConfirmButton:false,
        title: 'Límite de tiempo alcanzado',
        imageUrl: 'https://www.ubiipagos.com/img/logo.png',
        imageWidth:"200",
        allowOutsideClick:false,
        allowEscapeKey:false,
        timer: 5000,
        willClose: ()=>{
            func();
        }
    });
}