import {SET_DATA_CLIENT, PAGO_DATA, CHARGE_AMOUNT} from '../../types'
    
export default (state,action) =>{

    const {type, payload} = action;
    switch (type) {
        case PAGO_DATA:
            return{
                ...state,
                pagoData:payload,
                processPayment:true
            }
        case SET_DATA_CLIENT:
            return {
                ...state,
                data_client_tdc: payload
            }
        case CHARGE_AMOUNT:
            return{
                ...state,
                pagoData:payload,
                processChargeMicroCTDC: true
            }
        default:
            return state;
    }
}