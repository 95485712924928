import React,{useState,useEffect,useContext} from 'react';
import M from "materialize-css";
import { useForm, Controller } from "react-hook-form";
import InternacionalContext from '../../context/Internacional/internacionalContext';
import ClientContext from '../../context/Client/clientContext';
import Input from '../partials/Input';
import {uppercase,removeNonDigits, noNumbers, validateCard} from '../../helpers/str';
import Spinner from '../spinner/Spinner';
import Select from 'react-select';
import Tooltip from '../partials/Tooltip';
import { InputDisplayForCurrency } from '../partials/CurrencyMask';

export const customStyles = {
    control: ({ isFocused }) => ({
        backgroundColor: '#f3f3f3',
        border: '1.5px #f3f3f3 solid',
        borderRadius: '6px',
        padding:0,
        borderBottom: 'none',
        cursor: 'text',
        fontSize:'16px',
        display: 'flex'
    }),
    option: (styles, {isFocused}) => ({
        ...styles,
        backgroundColor: isFocused? 'rgba(0, 170, 235, 0.1)' : 'unset',
        color: isFocused? '#00a1fd' : '#676767',
        cursor: 'pointer',
        minHeight: 51,
        margin:'auto',
        display: 'flex',
        alignItems: 'center',
    }),
    placeholder: (defaultStyles) => ({
        ...defaultStyles,
        color:'rgba(28, 28, 28, 0.28)',
        fontWeight: 'bold',
        marginRight: '0px',
        marginLeft: '0px',
        left:'unset',
        top: '50%'
    }),
    singleValue: (styles) => ({ 
        ...styles, 
        color: '#00a1fd',
        marginRight: '0px',
        marginLeft: '0px',
        left:'unset',
        top: '50%',
        fontWeight: 'bold'
    }),
    menu: (styles) => ({
        ...styles,
        marginTop: '0px',
        borderRadius: '7px',
        zIndex:'10'
    }),
    menuList: (styles) => ({
        ...styles,
        paddingTop: '0px',
        border: 'solid 1px #00a1fd',
        paddingBottom: '0'
    }),
    input: (styles) => ({
        ...styles,
        marginLeft: '-9px'
    }),
    noOptionsMessage: (styles) => ({
        ...styles
    }),
    valueContainer: (styles) => ({
        ...styles,
        padding: "0 0 0 3%"
    })
};

const TdcInternacional = () => {

    //States
    const [interdata, guadarInter]=useState({
        card:'',
        name:'',
        month:'',
        year:'',
        cvv:'',
        zip:'',
        country:'',
        city:'',
        state:'',
        dir:''
    });

    const {card, name, month, year, cvv, zip, country, city, state, dir} = interdata;

    const internacionalContext = useContext(InternacionalContext);
    const {sendInternacional} = internacionalContext;

    const clientContext=useContext(ClientContext);
    const {styleBrowserDefault, years, months, direction, loading, info, countries, setLoading, setDirection}=clientContext;

    //Validación del form
    const { register, handleSubmit, trigger, control, formState: { errors } } = useForm({
        mode:"onBlur",
        reValidateMode:"onBlur"
    });

  /**
   * Método para actualizar el state del formulario dinámicamente
   * @function handleChange
   * @param {Event} e Evento que cambia un input en el form
   * @returns {void}
   */
   const handleChange=(e, errors)=>{
        if(errors){
            trigger(e.target.name);
        }
        if(e.label){
            guadarInter({
                ...interdata,
                country: e.label
            })
        }else {
            if(e.target.name==="name"){
                e.target.value=uppercase(e.target.value)
            }
            guadarInter({
                ...interdata,
                [e.target.name]:e.target.value
            })
        }
    }

    //Pedir habilitar la ubicación para obtener latitud y longitud, sino error
    useEffect(() =>{
        if(direction.latitude ===""){
            let mounted = true;
            if(navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position => {
                    if(mounted){
                        setDirection(prevState => ({
                            ...prevState,
                            latitude:position.coords.latitude,
                            longitude:position.coords.longitude
                        }));
                    }       
                }), (error)=>{
                    setDirection(prevState => ({
                        ...prevState,
                        latitude:"1",
                        longitude:"1"
                    }))
                    // if(error.code === error.PERMISSION_DENIED){
                    //     showError('6');
                    //     handleError(error);
                    //     timer(7000,"Recibo");
                    // }
                });
            } else {
                // showError('7');
                // handleError(new Error('7'));
                // timer(7000,"Recibo");
                setDirection(prevState => ({
                    ...prevState,
                    latitude:"1",
                    longitude:"1"
                }))
            }

            return () => mounted = false;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        M.AutoInit();
        window.scrollTo(0,0);
    }, [])

    /**
     * Recopila los datos necesarios, los transforma y va a realizar el pago
     * @function onSubmit
     * @param {object} interdata la data del form 
     * @returns {void}
     */
    const onSubmit=interdata =>{  
        setLoading(true)
        const message = {
            crdn: removeNonDigits(interdata.card),
            ncrd:uppercase(interdata.name),
            fexp: interdata.month +""+interdata.year.slice(2),
            cvv:interdata.cvv,
            m:info[0].amount_ds,
            cu:'USD',
            lat: direction.latitude.toString(),
            long: direction.longitude.toString(),
            order: info[0].orderId,
            zip: interdata.zip,
            dir: `${interdata.dir}, ${interdata.city}, ${interdata.state}, ${interdata.country}`,
            origin: "247"
        }
        sendInternacional(message)
    }
    
    return ( 
        <>
            {direction.latitude ==="" && <Spinner/>}
            <form 
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className="margin-top-08 col s12 l12 titles-3 center-align">
                    <h5 >Pagarás con <span>tarjeta de crédito</span> un monto en dólares de:</h5>
                </div>
                {loading && <Spinner/>}
                <div className="row">
                    <div className="input-field col s12">
                        <InputDisplayForCurrency 
                            amount={info[0].amount_ds}
                            insideForm
                        />
                    </div>

                    <div className="input-field col s12 center-align">
                        <div className="center-align">
                            <img src="/images/icons/card.svg" alt="tarjeta"/>
                            <div className="ubiiGrey">Datos de la tarjeta</div>
                        </div>
                    </div>

                    <div className="input-field col s12">
                        <label className='active label-payments' htmlFor="name">Nombre del titular de la tarjeta</label>
                        <Input
                            name="name"
                            id="name"
                            maxLength="40"
                            register={register("name", {
                                required:{value:true,message:'Nombre del tarjeta habiente es requerido'},
                                maxLength:{value:40,message:"este campo debe tener máximo 40 caracteres"},
                                validate: value => noNumbers(value) || 'Nombre del tarjeta habiente no válido',
                                onChange: (e) => handleChange(e, errors.name)
                            })}
                            placeholder="Nombre del titular que aparece en la tarjeta"
                            className={errors.name ? "red-border": "input-form"}
                        />
                        <span className="helper-text invalid" data-error="wrong" >
                            {errors.name && errors.name.message}
                        </span>
                    </div>

                    <div className="input-field col s12">
                        <label className='active label-payments' htmlFor="card">Número de tarjeta</label>
                        <Input
                            name="card" 
                            id="card"
                            mask="9999-9999-9999-99999"
                            register={register("card", {
                                required:{value:true,message:'Número de tarjeta es obligatorio'},
                                minLength:{value: 14, message:'Número de tarjeta debe tener mínimo 14 dígitos'},
                                validate: value => validateCard(value) || 'Número de tarjeta no válido',
                                onChange: (e) => handleChange(e, errors.card)
                            })}
                            placeholder="Ingresa tu número de tarjeta"
                            className={errors.card ? "red-border": "input-form"}
                        />
                        <span className="helper-text invalid" data-error="wrong" >
                            {errors.card && errors.card.message}
                        </span>
                    </div>

                    <div className="input-field col s12">
                        <div className="row fix-to-bottom no-margin-bottom">
                            <div className={`input-field col s12 m4 ${errors.month ? "border-input-dropdown-error" : ""}`}>
                                <label className='active label-payments' htmlFor="month">Mes de vencimiento</label>
                                <select
                                    name="month"
                                    id="month"
                                    {...register("month", {
                                        required:{value:true,message:'El mes es obligatorio'},
                                        onChange: (e) => handleChange(e, errors.month)
                                    })}
                                    className={`input-form ${styleBrowserDefault && 'browser-default'}`}
                                >
                                    <option value="">Mes -</option>
                                    {months && months.map(month=>(
                                        <option value={month.value} key={month.value}>{month.description}</option>
                                    ))}
                                </select>
                                <span className="helper-text invalid" data-error="wrong" >
                                    {errors.month && errors.month.message}
                                </span>
                            </div>

                            <div className={`input-field col s12 m4 ${errors.year ? "border-input-dropdown-error" : ""}`}>
                                <label className='active label-payments' htmlFor="year">Año de vencimiento</label>
                                <select
                                    name="year"
                                    id="year"
                                    {...register("year", {
                                        required:{value:true,message:'El año es obligatorio'},
                                        onChange: (e) => handleChange(e, errors.year)
                                    })}
                                    className={`input-form ${styleBrowserDefault && 'browser-default'}`}
                                >
                                    <option value="">Año -</option>
                                    {years}
                                </select>
                                <span className="helper-text invalid" data-error="wrong" >
                                    {errors.year && errors.year.message}
                                </span>
                            </div>

                            <div className="input-field col s12 m4">
                                <label className='active label-payments' htmlFor="cvv">CVV</label>
                                <Tooltip 
                                    message="Son los tres dígitos que se ubican en la parte inversa de tu tarjeta" 
                                    icon="info_outline"
                                    iconClass="info-icon"
                                />
                                <Input
                                    name="cvv"
                                    id="cvv"
                                    type="password"
                                    mask="9999"
                                    register={register("cvv", {
                                        required:{value:true,message:'El cvv es obligatorio'},
                                        maxLength:{value:4,message:"El cvv debe tener máximo 4 dígitos"},
                                        minLength:{value:3,message:"El cvv debe tener minimo 3 dígitos"},
                                        onChange: (e) => handleChange(e, errors.cvv)
                                    })}
                                    className={errors.cvv ? "red-border": "input-form"}
                                />
                                <span className="helper-text invalid" data-error="wrong" >
                                    {errors.cvv && errors.cvv.message}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="input-field col s12 center-align">
                        <div className="center-align">
                            <img src="/images/icons/address.svg" alt="dirección"/>
                            <div className="ubiiGrey">Dirección de facturación</div>
                        </div>
                    </div>

                    <div className="input-field col s12">
                        <label className='active label-payments' htmlFor="country">&#10991; País</label>
                        <Controller
                            id="country-drop"
                            control={control}
                            name="country"
                            autoComplete="off"
                            rules={{required:{value:true,message:'El país es requerido'}}}
                            render={({ field: { onChange } }) => (
                                <Select
                                    options={countries}
                                    components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                                    styles={customStyles}
                                    classNamePrefix="react-select"
                                    noOptionsMessage={() => 'País no encontrado'}
                                    placeholder="Ingresa el país"
                                    onChange={(e) => {
                                        onChange(e.label);
                                        e.target={name: "country"};
                                        handleChange(e, errors.country);
                                    }}
                                    autoComplete="off"
                                />
                            )}
                        />
                        <span className="helper-text invalid" data-error="wrong" >
                            {errors.country && errors.country.message}
                        </span>
                        </div>

                    <div className="input-field col s12">
                        <label className='active label-payments' htmlFor="city">&#10991; Ciudad</label>
                        <Input
                            name="city" 
                            id="city" 
                            maxLength="45"
                            register={register("city", {
                                required:{value:true,message:'La ciudad es obligatoria'},
                                validate: value => noNumbers(value) || 'La ciudad no es válida',
                                onChange: (e) => handleChange(e, errors.city)
                            })}
                            className={errors.city ? "red-border": "input-form"}
                            placeholder="Ingresa la ciudad"
                        />
                        <span className="helper-text invalid" data-error="wrong" >
                            {errors.city && errors.city.message}
                        </span>
                    </div>

                    <div className="input-field col s12">
                        <label className='active label-payments' htmlFor="state">&#10991; Estado</label>
                        <Input
                            name="state" 
                            id="state"
                            maxLength="45"
                            register={register("state", {
                                required:{value:true,message:'El estado es requerido'},
                                validate: value => noNumbers(value) || 'El estado no es válido',
                                onChange: (e) => handleChange(e, errors.state)
                            })}
                            className={errors.state ? "red-border": "input-form"}
                            placeholder="Ingresa el estado"
                        />
                        <span className="helper-text invalid" data-error="wrong" >
                            {errors.state && errors.state.message}
                        </span>
                    </div>

                    <div className="input-field col s12">
                        <label className='active label-payments' htmlFor="dir">&#10991; Dirección (Casa, Avenida, Calle)</label>
                        <Input
                            name="dir" 
                            id="dir"
                            register={register("dir", {
                                required:{value:true,message:'La dirección es obligatoria'},
                                onChange: (e) => handleChange(e, errors.dir)
                            })}
                            className={errors.dir ? "red-border": "input-form"}
                            placeholder="Ingresa la dirección"
                        />
                        <span className="helper-text invalid" data-error="wrong" >
                            {errors.dir && errors.dir.message}
                        </span>
                    </div>

                    <div className="input-field col s12">
                        <label className='active label-payments' htmlFor="zip">&#10991; Código Postal</label>
                        <Input
                            name="zip"
                            id="zip"
                            maxLength="10"
                            register={register("zip", {
                                required:{value:true,message:'El zip es requerido'},
                                onChange: (e) => handleChange(e, errors.zip)
                            })}
                            className={errors.zip ? "red-border": "input-form"}
                            placeholder="Ingresa el código postal"
                        />
                        <span className="helper-text invalid" data-error="wrong" >
                            {errors.zip && errors.zip.message}
                        </span>
                    </div>

                    <div className="input-field col s12">
                        <button
                            className="buttonPay"
                            disabled={
                                name==="" || 
                                zip==="" || 
                                country==="" || 
                                city ==="" || 
                                state==="" || 
                                dir==="" ||
                                cvv==="" || 
                                card==="" ||
                                year==="" ||
                                month===""
                            }
                        >
                            ¡Listo!
                        </button>
                    </div>
                </div>
            </form>
        </>
     );
}
 
export default TdcInternacional;