import { PAGO_UBII } from '../../types';

export default (state, action) => {

    const {type, payload} = action;

    switch (type) {
        case PAGO_UBII:
            return {
                ...state,
                dataUbiiApp: payload,
                processUbiiApp:true
            }
        default:
            return state;
    }

}