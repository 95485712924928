import React, { useContext } from 'react';
import errorContext from '../context/Error/errorContext';

const NotFound = ({message, image, retryButton}) => {
    
    const { code } = useContext(errorContext);
    return( 
        <div className="row valign-wrapper">
            <div className="center col card-panel shadow" style={{zIndex: "2", width: "90%"}}>
                <div className="payment">
                    <div  style={{visibility: process.env.REACT_APP_UBII_LOGO==='false'?'hidden':'visible', height:"2.6rem"}}><img  style={{height:"inherit"}} src="https://www.ubiipagos.com/img/logo.png" alt="logo"/></div>
                    <div><img className="errorImage" src={image} alt="error_image"/></div>
                    <h5 className="ubiBlue2" style={{marginTop:"2rem", marginBottom:"2rem"}} data-testid="error-screen">{message}</h5>
                    {(code === "8" || retryButton===true) &&
                        <div className="s4" style={{marginTop:"-0.5rem", marginBottom:"2rem"}}>
                            <button
                                className="buttonPay2 buttonTryAgain"
                                onClick={()=> window.location.reload()}
                            >
                                Volver a intentarlo
                            </button>
                        </div>
                    }
                </div>
            </div> 
        </div>

    );
};

export default NotFound;