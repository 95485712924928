import React, { useContext } from 'react';
import clientContext from '../context/Client/clientContext';
import useScreenSize from './customHooks/useScreenSize';

const BackButton = () => {

    const {payment, nextStep} = useContext(clientContext);
    const { width } = useScreenSize();

    /**
     * Lo que debe realizar el botón "ir atrás"
     * @function handleClick
     * @param {Event} e evento de click 
     * @returns {void}
     */
    const HandleClick = e =>{
        e.preventDefault();
        nextStep(payment-1);
    }

    return (
        <>
            {payment > 1 &&
                (
                    <button 
                        className="btnBack"
                        onClick={HandleClick}
                        data-testid="back-button"
                    >
                        <img 
                            style={{height:width>600 && '1.5rem'}} 
                            src={`/images/icons/${width<=600? 'flecha.png' : 'flecha-desktop.svg'}`} 
                            alt="Ir atrás"
                        />
                        <span className="volver">
                            {width>600 && 'Volver'}
                        </span>
                    </button>
                    
                )
            }
        </>
     );
}
 
export default BackButton;