import React from "react";
import "./spinner.css";

const Spinner = ({ justLoader }) => {
    return (
        <div id={`${justLoader? '' : 'preloader-overlay'}`} className={`${justLoader? 'paddings-just-loader' : 'valign-wrapper'}`}>
            <div className={`preloader-wrapper big active loading ${justLoader ? '' : 'absolute-loading'}`}>
                <div className="spinner-layer spinner-blue-only">
                    <div className="circle-clipper left">
                        <div className="circle"></div>
                    </div>
                    <div className="gap-patch">
                        <div className="circle" data-testid="loading"></div>
                    </div>
                    <div className="circle-clipper right">
                        <div className="circle"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Spinner;
