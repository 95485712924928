import {PAGO_P2C} from '../../types';

export default (state,action) =>{

    const {type, payload} = action;

 switch (type) {
     case PAGO_P2C:
        return{
            ...state,
            dataP2c:payload,
            processP2c:true,
            loading:false
        }
        default:
            return state;
 }
}