import React, { useState,useEffect, useContext } from "react";
import M from "materialize-css";
import ClientContext from '../../context/Client/clientContext';
import { useForm, Controller } from "react-hook-form";
import Spinner from "../spinner/Spinner";
import Input from '../partials/Input';
import {removeNonDigits, validateRif} from '../../helpers/str';
import { InputDisplayForCurrency } from "../partials/CurrencyMask";
import C2pContext from "../../context/C2p/c2pContext";
import Select from 'react-select';
import { customStyles } from "../internacional/TdcInternacional";

const C2p = () => {

  //States
    const [c2p, guardarC2p] = useState({
        bank:'',
        letter:'V',
        dni:'',
        phoneP:'',
        token:''
    });

    const {bank, phoneP, token, dni, letter} = c2p;

    const c2pContext = useContext(C2pContext);
    const {sendC2p} = c2pContext;

    const clientContext=useContext(ClientContext);
    const {styleBrowserDefault, direction, loading, method, info, setLoading, setDirection}=clientContext;


    //Validación del form
    const { register, handleSubmit, trigger, control, formState: { errors } } = useForm({
        mode:"onBlur",
        reValidateMode:"onBlur"
    });

  /**
   * Método para actualizar el state del formulario dinámicamente
   * @function handleChange
   * @param {Event} e Evento que cambia un input en el form
   * @returns {void}
   */
    const handleChange=(e, errors)=>{
        if(errors){
            trigger(e.target.name);
        }
        if(e.bank_code){
            guardarC2p({
                ...c2p,
                bank: e.bank_code
            });
        }else {
            if(e.target.name==="phoneP" && e.target.value==="+58 ("){
                e.target.value=""
            }
            guardarC2p({
            ...c2p, 
            [e.target.name]:e.target.value
            })
        };
    }
  
    //Modifica estilos
    useEffect(() => {
        M.AutoInit();
        window.scrollTo(0,0);
    }, []);

  //Pedir habilitar la ubicación para obtener latitud y longitud, sino error
    useEffect(() =>{
        if(direction.latitude ===""){
            let mounted = true;
            if(navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position => {
                    if(mounted){
                        setDirection(prevState => ({
                            ...prevState,
                            latitude:position.coords.latitude,
                            longitude:position.coords.longitude
                        }));
                    }       
                }), (error)=>{
                    setDirection(prevState => ({
                        ...prevState,
                        latitude:"1",
                        longitude:"1"
                    }))
                    // if(error.code === error.PERMISSION_DENIED){
                    //     showError('6');
                    //     handleError(error);
                    //     timer(7000,"Recibo");
                    // }
                });
            } else {
                // showError('7');
                // handleError(new Error('7'));
                // timer(7000,"Recibo");
                setDirection(prevState => ({
                    ...prevState,
                    latitude:"1",
                    longitude:"1"
                }))
            }

            return () => mounted = false;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Recopila los datos necesarios, los transforma y va a realizar el pago
     * @function onSubmit
     * @param {object} interdata la data del form 
     * @returns {void}
     */
    const onSubmit =  interdata => {

        setLoading(true)
        const message = {
            bank:interdata.bank,
            pin: interdata.token,
            ci:interdata.letter+""+interdata.dni,
            m:info[0].amount_bs,
            cp: info[0].concept ? info[0].concept : `COMPRA${info[0].orderId}`,
            phoneP:removeNonDigits(interdata.phoneP),
            order: info[0].orderId,
            lat: direction.latitude.toString() || "N/A",
            long: direction.longitude.toString() || "N/A"
        }
        sendC2p(message);
    }

    return (
        <>
            {direction.latitude ==="" && <Spinner/>}
            <form 
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className="margin-top-08 col s12 l12 titles-3 center-align">
                    <h5 >Vas a pagar con <span>C2P</span> un monto en Bs. de:</h5>
                </div>
                {loading && <Spinner />}
                <div className="row">
                    <div className="input-field col s12">
                        <InputDisplayForCurrency 
                            amount={info[0].amount_bs}
                            insideForm
                        />
                    </div>

                    <div className="input-field col s12">
                        <label className='active label-payments' htmlFor="bank">Selecciona el banco de tu tarjeta</label>
                        <Controller
                            id="bank-drop"
                            control={control}
                            name="bank"
                            rules={{required:{value:true,message:'El banco es requerido'}}}
                            render={({ field: { onChange } }) => (
                                <Select
                                    options={method.banks_c2p}
                                    getOptionLabel ={(option)=>option.bank_name}
                                    getOptionValue ={(option)=>option.bank_code}
                                    components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                                    styles={customStyles}
                                    classNamePrefix="react-select"
                                    noOptionsMessage={() => 'Banco no encontrado'}
                                    placeholder="Selecciona el banco"
                                    onChange={(e) => {
                                        onChange(e.bank_code);
                                        e.target={name: "bank"};
                                        handleChange(e, errors.bank);
                                    }}
                                    autoComplete="off"
                                />
                            )}
                        />
                        <span className="helper-text invalid" data-error="wrong" >
                            {errors.bank && errors.bank.message}
                        </span>
                    </div>

                    <div className="col s12 label-dni">
                        <label className='active label-payments' htmlFor="letter">Datos de tu documento de identidad</label>
                    </div>
                    <div className={`input-field col s8 offset-s2 m3 offset-m0 center-align no-margin-bottom`}>
                        <select
                            name="letter"
                            id="letter"
                            {...register("letter", {
                                required:{value:true,message:'La nacionalidad es obligatoria'},
                                onChange: (e) => handleChange(e, errors.letter)
                            })}
                            className={`input-form ${styleBrowserDefault && 'browser-default'}`}
                            autoComplete="off"
                        >
                            <option value="V">V</option>
                            <option value="E">E</option>
                            <option value="J">J</option>
                            <option value="G">G</option>
                            <option value="C">C</option>
                        </select>
                    </div>

                <div className="input-field col s12 m9">
                    <Input
                        name="dni"
                        id="dni"
                        mask="999999999"
                        register={register("dni", {
                            required:{value:true,message:'Este campo es obligatorio'},
                            validate: value => validateRif(value, letter) || "El RIF no es válido",
                            ...(letter==="J" ?
                                {minLength:{value:9,message:"Este campo debe tener 9 caracteres"}} : 
                                {minLength:{value:6,message:"Este campo debe tener al menos 6 caracteres"}}
                            ),
                            onChange: (e) => handleChange(e, errors.dni)
                        })}
                        placeholder="Cédula/RIF"
                        className={errors.dni ? "red-border": "input-form"}
                    />
                    <span className="helper-text invalid" data-error="wrong" >
                        {errors.dni && errors.dni.message}
                    </span>
                </div>

                    <div className="input-field col s12">
                        <label className='active label-payments' htmlFor="phoneP">Ingresa tu número de teléfono</label>
                        <Input
                            name="phoneP" 
                            id="phoneP"
                            mask="+58 (999) 999 99 99"
                            register={register("phoneP", {
                                required:{value:true,message:'El número telefónico del cliente es obligatorio'},
                                minLength:{value:19,message:"Coloque un número telefónico válido"},
                                onChange: (e) => handleChange(e, errors.phoneP)
                            })}
                            placeholder="+58 ("
                            className={errors.phoneP ? "red-border": "input-form"}
                            inputMode="numeric"
                            // autoComplete="off"
                        />
                        <span className="helper-text invalid" data-error="wrong" >
                            {errors.phoneP && errors.phoneP.message}
                        </span>
                    </div>

                    <div className="input-field col s12">
                        <label className='active label-payments' htmlFor="token">Token</label>
                        <Input
                            name="token"
                            id="token"
                            mask="********************"
                            register={register("token", {
                                required:{value:true,message:'El token es obligatorio'},
                                onChange: (e) => handleChange(e, errors.token)
                            })}
                            placeholder="Ingresa el token"
                            className={errors.token ? "red-border": "input-form"}
                            autoComplete="off"
                        />
                        <span className="helper-text invalid" data-error="wrong" >
                        {errors.token && errors.token.message}
                        </span>
                    </div>

                    <div className="input-field col s12">
                        <button
                        className="buttonPay"
                        disabled={token==="" || phoneP==="" || bank==="" || letter==="" || dni===""}
                        >
                        ¡Listo!
                        </button>
                    </div>
                </div> 
            </form>
        </>
    );
};

export default C2p;
