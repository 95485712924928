import { PAGO_ZELLE, TOKEN_ZELLE } from '../../types';

export default (state, action) => {

    const {type, payload} = action;

    switch (type) {
        case PAGO_ZELLE:
            return {
                ...state,
                dataZelle: payload,
                processZelle:true
            }
        case TOKEN_ZELLE:
            return {
                ...state,
                tokenMW: payload
            }
        default:
            return state;
    }

}