import {PAGO_C2P} from '../../types';

export default (state,action) =>{

    const {type, payload} = action;

 switch (type) {
     case PAGO_C2P:
        return{
            ...state,
            dataC2p:payload,
            processC2p:true,
            loading:false
        }
        default:
            return state;
 }
}