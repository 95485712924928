import React from 'react';
import Input from "../Input";


const Email = ({message, register, errors}) => (
    <div className="input-field col s12">
        <label className='active label-payments' htmlFor="email">Correo Electrónico</label>
        <Input
        name="email"
        id="email"
        register={register}
        placeholder="Ingresa tu correo"
        className={errors ? "red-border": "input-form"}
        />
        <span className="helper-text invalid" data-error="wrong" >
        {errors && errors.message}
        </span>
        {message && <span style={{color:"#635bff"}}>Se enviará a tu correo un código de validación para efectuar el pago.</span>}
    </div>
)
 
export default Email;