import React from 'react';
import Input from "../Input";

const PhoneP = ({message, register, errors}) => (
    <div className="input-field col s12">
        <label className='active label-payments' htmlFor="phoneP">Número de Teléfono</label>
        <Input
            name="phoneP" 
            id="phoneP"
            mask="+58 (999) 999 99 99" 
            register={register}
            placeholder="Ingresa tu número de teléfono"
            className={errors ? "red-border": "input-form"}
        />
        <span className="helper-text invalid" data-error="wrong" >
            {errors && errors.message}
        </span>
        {message && <span style={{color:"#635bff"}}>Se enviará a tu teléfono un código de validación para efectuar el pago.</span>}
    </div>
)
 
export default PhoneP;