import React , {useReducer, useContext} from 'react';
import P2cContext from '../P2c/p2cContext';
import p2cReducer from '../P2c/p2cReducer';
import { PAGO_P2C } from '../../types';
import ClientContext from '../Client/clientContext';
import errorContext from '../Error/errorContext';

const P2cState = (props) => {

    //State
    const initialState ={
        dataP2c:null,
        processP2c:false
    }

    const clientContext=useContext(ClientContext);
    const {setLoading,method,handleError,pay}=clientContext; 
    const { showError } = useContext(errorContext);

    //dispacth para ejecutar las acciones
    const [state, dispatch] = useReducer(p2cReducer,initialState);

    /**
     * Envía datos al endpoint final de p2c. Coloca en el contexto el resultado de la transacción, o el error si hubo alguno 
     * @async
     * @function sendP2c
     * @param {object} datos información enviada desde el formulario de p2c
     * @returns {void}
     */
    const sendP2c = async datos =>{
        try {
            const response = await pay('payment_pago_movil', datos, method.btn_key);
            dispatch({
                type:PAGO_P2C,
                payload:response
            })
            setLoading(false)

        } catch (error) {
            console.log(error);
            showError('1');
            handleError(error);
            setLoading(false)
        }
    }

    return ( 
        <P2cContext.Provider
            value={{
                dataP2c: state.dataP2c,
                processP2c:state.processP2c,
                sendP2c
            }}
        >
            {props.children}
        </P2cContext.Provider>
     );
}
 
export default P2cState;